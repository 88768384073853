import { Box, Button, Grid, useMediaQuery, useTheme, Card, Chip, CardContent, IconButton, TextField, InputAdornment } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSearchResourcesAction } from '../../actions/search';
import SearchIcon from '@mui/icons-material/Search';


const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : 'auto',
    '&:hover': { cursor: 'pointer' },
}));

const StyledTextField = styled(TextField)(({ isMobile }) => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#bdec5b',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#bdec5b',
        },
        '&:hover fieldset': {
            borderColor: '#bdec5b',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#bdec5b',
        },
        backgroundColor: "#FFFFFF1A",
        color: 'white',
        height: isMobile ? "45px" : "50px",
        width: isMobile ? '100%' : '700px',
        borderRadius: "40px"
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
    marginBottom: '30px'
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff',
}));

const InspirePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const { searchQuery } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [resources, setResources] = useState([]);
    const [searchText, setSearchText] = useState("");

    const handleSearch = () => {
        dispatch(getSearchResourcesAction(searchText))
            .then((res) => {
                setResources(res.data);
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        handleSearch("");
    }, []);

    return (
        <Box
            sx={{
                padding: isMobile ? '24px' : '28px',
                paddingTop: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: isMobile ? "left" : "center",
                alignItems: isMobile ? "left" : "center",
            }}
        >
            <Typography fontFamily={"Inter"} fontWeight={900} fontSize={'24px'} color={"#fff"} mt={2} mb={0} lineHeight={'29px'}>
                Resources
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <StyledTextField
                    isMobile={isMobile}
                    margin="normal"
                    fullWidth
                    name="search"
                    placeholder='Search...'
                    value={searchText}
                    onChange={(e) => { setSearchText(e.target.value) }}
                    onKeyDown={(e) => { if (e.keyCode == 13) { handleSearch() } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => { handleSearch() }}>
                                    <SearchIcon style={{ color: "#fff" }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Grid container direction="column" spacing={2} style={{ width: isMobile ? "auto" : "700px" }}>
                {
                    resources?.resources?.map((resource) => {
                        return (
                            <Grid item style={{ width: "100%" }}>
                                <a href={resource?.url} target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    <StyledCard isMobile={isMobile} sx={{ '&:hover': { cursor: 'pointer' }, maxWidth: isMobile ? "auto" : "100%" }}>
                                        <CardContent style={{ paddingBottom: '16px', "&:last-child": { paddingBottom: '16px' } }}>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                        {resource?.title}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'13px'} color={"#fff"} lineHeight={'19px'}>
                                                        {resource?.description}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <StyledChip label={resource?.resource_type} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </StyledCard>
                                </a>
                            </Grid>
                        )
                    })
                }
            </Grid>

        </Box >
    )
}

export default InspirePage;
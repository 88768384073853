import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const StickyFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100%',
                paddingBottom: '10px',
                marginTop: 'auto'
            }}
        >
            <Typography style={{ color: '#fff' }} fontSize={'13px'} fontWeight={400} lineHeight={"19.5px"} fontFamily={"Inter"}>© 2024 Something</Typography>
        </Box>
    );
}

export default StickyFooter;
import { Box, Button, Grid, useMediaQuery, useTheme, Card, CardContent, styled, Typography, Chip, IconButton, TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import StarIcon from '../../assets/icons/dashboard/star.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlurryPlaceHolder from '../../assets/placeholderBlurry.png';

import { getAllGuidesAction } from '../../actions/challange';

const StyledChallangeCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : '115px',
    // '&:hover': { cursor: 'pointer' }
}));

const StyledChallengeChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const ChallangesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [challanges, setChallanges] = useState([]);

    const handleViewGuide = (e, guide) => {
        e.preventDefault();
        dispatch({ type: "UPDATE_GUIDE", payload: guide });
        navigate("/challangedetails");
        e.stopPropagation();
    }

    useEffect(() => {
        dispatch(getAllGuidesAction())
            .then((res) => {
                setChallanges(res.data.guide_types.slice(0, 2));
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >

            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 4,
                        marginBottom: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"18px"} fontFamily={"Inter"} fontWeight={400} mb={0}>
                        Challenges are our bite-sized way of tackling climate action.
                    </Typography>

                    &nbsp;

                    <Typography color="#fff" fontSize={"18px"} fontFamily={"Inter"} fontWeight={400} mb={0}>
                        You may want to start with learning through our series of Discovery guides. These are broken into ten down-to-earth chapters, each 3-5 mins, and designed to be done on your break or daily commute.
                    </Typography>

                    &nbsp;

                    <Typography color="#fff" fontSize={"18px"} fontFamily={"Inter"} fontWeight={400} mb={1}>
                        If you want to take action then dive into one of our light-touch behavioural change guides. These lay out the environmental context, benefits of taking this action as well as a step-by-step guide to doing it successfully.
                    </Typography>

                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 4,
                        marginBottom: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={600} mb={2}>
                        Challenges
                    </Typography>

                    <Grid container direction={isMobile ? "column" : "row"} spacing={3}>
                        {
                            challanges?.length === 0 ? (
                                <>
                                    <Typography fontFamily={"Roboto"} fontWeight={600} fontSize={'18px'} color={"#fff"} mt={4} ml={2} lineHeight={'26px'}>
                                        No Challanges Found
                                    </Typography>
                                </>
                            ) : (
                                challanges?.map((obj1, key) => {
                                    return (
                                        obj1?.guides?.map((obj, key) => {
                                            return (
                                                <Grid key={obj.id} item xs={isMobile ? 12 : 6}>
                                                    <StyledChallangeCard isMobile={isMobile}>
                                                        <CardContent>
                                                            <Grid container spacing={2} wrap="noWrap">
                                                                <Grid item>
                                                                    <LazyLoadImage src={obj?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" width="85px" height="85px" style={{ borderRadius: '12px' }} />
                                                                </Grid>

                                                                <Grid item >
                                                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                                        {obj.title}
                                                                    </Typography>

                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: '6px',
                                                                            marginTop: '6px'
                                                                        }}
                                                                    >
                                                                        <StyledChallengeChip label={obj?.guide_type} />

                                                                        {
                                                                            obj?.themes?.length !== 0 && (
                                                                                obj.themes.map((theme) => {
                                                                                    return (
                                                                                        <StyledChallengeChip label={theme} />
                                                                                    )
                                                                                })
                                                                            )
                                                                        }

                                                                        <InfoIcon onClick={(e) => handleViewGuide(e, obj)} style={{ cursor: 'pointer' }} />
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: 'inline-flex',
                                                                            marginTop: '10px',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                                                            <img src={StarIcon} alt="" style={{ height: '20px' }} />
                                                                        </IconButton>

                                                                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                                                            {obj.total_points}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </StyledChallangeCard>
                                                </Grid>
                                            )
                                        })
                                    )
                                })
                            )
                        }
                    </Grid>

                </Box>
            </Box>
        </>
    )
};

export default ChallangesPage;
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { editUserAction } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        backgroundColor: "#FFFFFF1A",
        color: 'white',
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
    marginBottom: '15px'
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const EditProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.auth.user);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues: {
            firstName: userDetails.first_name,
            lastName: userDetails.last_name,
            emailAddress: userDetails.email,
            phoneNumber: userDetails.phone_number
        },
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const validateFormData = (formData) => {
        let isValid = true;

        if (formData.firstName === "") {
            setError('firstName', { message: "Please enter your first name." });
            isValid = false;
        }

        if (formData.lastName === "") {
            setError('lastName', { message: "Please enter your last name." });
            isValid = false;
        }

        if (formData.phoneNumber === "") {
            setError('phoneNumber', { message: "Please enter your phone number." });
            isValid = false;
        }

        if (formData.emailAddress === "") {
            setError('emailAddress', { message: "Please enter a valid email address." });
            isValid = false;
        }

        // if (formData.password === "") {
        //     setError('password', { message: "Please enter your password." });
        //     isValid = false;
        // }

        return isValid;
    }

    const submitForm = (formData) => {

        clearErrors();
        setLoading(true);
        if (validateFormData(formData)) {
            dispatch(editUserAction(formData, selectedFile, userDetails?.id))
                .then((response) => {
                    if (response) {
                        // navigate("/dashboard");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        setLoading(false);
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid container direction="column" spacing={3} width={isMobile ? '100%' : '700px'}>
                <Grid item>
                    <Box
                        onClick={() => navigate(-1)}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': { cursor: 'pointer' }
                        }}
                    >
                        <IconButton>
                            <ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
                        </IconButton>

                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                            Back
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Typography fontFamily={"Roboto"} fontWeight={600} fontSize={'20px'} color={"#fff"} mb={2}>
                Edit Profile
            </Typography>

            <form onSubmit={handleSubmit(submitForm)}>
                <Box sx={{ mt: 1, width: isMobile ? '90%' : '50%', margin: 'auto' }}>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        mt={1}
                        mb={1}
                    >
                        {
                            userDetails?.avatar_url ? (
                                <img
                                    src={userDetails?.avatar_url}
                                    alt={""}
                                    style={{
                                        color: "#fff",
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "100px",
                                    }}
                                />
                            ) : (
                                <AccountCircleIcon sx={{ color: "#fff", width: "42px", height: "42px" }} />
                            )
                        }
                    </Box>

                    <StyledTextField
                        margin="normal"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        {...register('firstName')}
                        error={!!errors.firstName}
                        helperText={errors?.firstName?.message}
                        disabled={loading}
                    />

                    <StyledTextField
                        margin="normal"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        {...register('lastName')}
                        error={!!errors.lastName}
                        helperText={errors?.lastName?.message}
                        disabled={loading}
                    />

                    {/* <StyledTextField
                        margin="normal"
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        {...register('phoneNumber')}
                        error={!!errors.phoneNumber}
                        helperText={errors?.phoneNumber?.message}
                        disabled={loading}
                    /> */}

                    <StyledTextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        {...register('emailAddress')}
                        error={!!errors.emailAddress}
                        helperText={errors?.emailAddress?.message}
                        disabled={loading}
                    />

                    {/* <StyledTextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility style={{ color: '#fff' }} /> : <VisibilityOff style={{ color: '#fff' }} />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...register('password')}
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        disabled={loading}
                    /> */}

                    <Typography fontFamily={"Roboto"} fontWeight={400} fontSize={'18px'} color={"#fff"} display="inline" style={{ marginRight: "10px" }}>
                        Profile Image
                    </Typography>

                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload Profile Photo
                        <VisuallyHiddenInput type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                    </Button>

                    {
                        selectedFile && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    gap: '30px'
                                }}
                            >
                                <img
                                    src={URL.createObjectURL(selectedFile)}
                                    alt={`Attachments: ${selectedFile?.name}`}
                                    width="80px"
                                    height="80px"
                                    style={{
                                        borderRadius: "10px"
                                    }}
                                />
                            </Box>
                        )
                    }

                    <StyledButton
                        fullWidth
                        variant="contained"
                        sx={{ mt: 4, mb: 2 }}
                        type="submit"
                        disabled={loading}
                    >
                        Update
                    </StyledButton>

                </Box>
            </form>
        </Box >
    )
}

export default EditProfile;
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk'; // Import redux-thunk
import rootReducer from './reducers/index';

const logger = createLogger({
    collapsed: true,
});

const configureStore = () => {
    let middlewares = [];
    if (process.env.NODE_ENV === "development") {
        middlewares = [...middlewares, thunk, logger];
    } else {
        middlewares = [...middlewares, thunk];
    }

    const store = createStore(
        rootReducer,
        compose(applyMiddleware(...middlewares))
    );

    return store;
};

export default configureStore;
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getAllPosts = async (page, id) => {
    const token = localStorage.getItem("token");

    let QUERY_PARAMS = `/posts/?page=${page}&per_page=10`;

    if (id) {
        QUERY_PARAMS += `&user_id=${id}`;
    }

    const response = await axios.get(`${API_URL}${QUERY_PARAMS}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
};

const createPost = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/posts`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
};

const addNewComment = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/comments`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
};

const addLike = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/likes`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
};

const removeLike = async (likeId) => {
    const token = localStorage.getItem("token");

    const response = await axios.delete(`${API_URL}/likes/${likeId}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
};

const deleteComment = async (commentId) => {
    const token = localStorage.getItem("token");

    const response = await axios.delete(`${API_URL}/comments/${commentId}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
}

export {
    getAllPosts,
    createPost,
    addNewComment,
    addLike,
    removeLike,
    deleteComment
};
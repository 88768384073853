import { Box, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
// import { toggleLoading } from '../../actions/loading';

const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        backgroundColor: "#FFFFFF1A",
        color: 'white',
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
    marginBottom: '15px',
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
}));

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const validateFormData = (formData) => {
        let isValid = true;

        if (formData.emailAddress === "") {
            setError('emailAddress', { message: "Please enter a valid email address." });
            isValid = false;
        }
        return isValid;
    }

    const handleLoginSubmit = (formData) => {
        clearErrors();
        setLoading(true);
        if (validateFormData(formData)) {
            const payload = {
                email: formData.emailAddress,
            }

            // dispatch(loginAction(payload))
            //     .then((response) => {
            //         if (response) {
            //             navigate("/dashboard");
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     })
        }
        setLoading(false);
    }

    return (
        <Box
            sx={{
                paddingTop: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography fontFamily={"Attila Sans"} fontWeight={600} fontSize={'24px'} color={"#fff"} mb={3} lineHeight={"28.8px"}>
                Forgot Password ?
            </Typography>

            <form onSubmit={handleSubmit(handleLoginSubmit)}>
                <Box sx={{ width: isMobile ? '90%' : '100%', margin: 'auto' }}>
                    <Typography fontFamily={"Inter"} fontSize={"16px"} fontWeight={400} lineHeight={"24px"} color={"#fff"} mb={4}>
                        Please enter your email address. You will receive a link to create a new password via email.
                    </Typography>

                    <StyledTextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        disabled={loading}
                        style={{ marginBottom: '25px' }}
                        {...register('emailAddress')}
                        error={!!errors.emailAddress}
                        helperText={errors?.emailAddress?.message}
                    />

                    <StyledButton
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        type='submit'
                        disabled={loading}
                    >
                        Reset Password
                    </StyledButton>
                </Box>
            </form>
        </Box >
    )
}

export default Login;
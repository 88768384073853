import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { loginAction } from '../../actions/auth';
// import { toggleLoading } from '../../actions/loading';

const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        backgroundColor: "#FFFFFF1A",
        color: 'white',
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
    marginBottom: '15px',
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
}));

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        setError,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const validateFormData = (formData) => {
        let isValid = true;

        if (formData.emailAddress === "") {
            setError('emailAddress', { message: "Please enter a valid email address." });
            isValid = false;
        }

        if (formData.password === "") {
            setError('password', { message: "Please enter your password." });
            isValid = false;
        }

        return isValid;
    }

    const handleLoginSubmit = (formData) => {
        clearErrors();
        setLoading(true);
        if (validateFormData(formData)) {
            const payload = {
                email: formData.emailAddress,
                password: formData.password
            }

            dispatch(loginAction(payload))
                .then((response) => {
                    if (response) {
                        navigate("/dashboard");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        setLoading(false);
    }

    // dispatch(toggleLoading(true));

    return (
        <Box
            sx={{
                paddingTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: '#bdec5b' }}>
                <LockOutlinedIcon style={{ backgroundColor: '#bdec5b', color: "#000" }} />
            </Avatar>
            <Typography fontFamily={"Roboto"} fontWeight={600} fontSize={'20px'} color={"#fff"} mb={2}>
                Log in
            </Typography>

            <form onSubmit={handleSubmit(handleLoginSubmit)}>
                <Box sx={{ mt: 1, width: isMobile ? '90%' : '100%', margin: 'auto' }}>
                    <StyledTextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        disabled={loading}
                        style={{ marginBottom: '25px' }}
                        {...register('emailAddress')}
                        error={!!errors.emailAddress}
                        helperText={errors?.emailAddress?.message}
                    />

                    <StyledTextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        disabled={loading}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility style={{ color: '#fff' }} /> : <VisibilityOff style={{ color: '#fff' }} />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...register('password')}
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                    />

                    <StyledButton
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        type='submit'
                        disabled={loading}
                    >
                        Log In
                    </StyledButton>

                    <Grid container direction={'column'} alignContent={'center'} textAlign={'center'} spacing={2} mt={2}>
                        {/* <Grid item xs>
                            <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'16px'} color={"#fff"} display="inline">
                                Forgot Your Password?&nbsp;&nbsp;
                            </Typography>

                            <Typography fontFamily={"Inter"} fontWeight={600} fontSize={'16px'} color={"#fff"} sx={{ textDecoration: 'underline', textDecorationColor: '#fff', "&:hover": { cursor: "pointer" } }} display="inline" onClick={() => navigate('/forgotPassword')}>
                                Reset it here
                            </Typography>
                        </Grid> */}
                        <Grid item>
                            <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'16px'} color={"#fff"} display="inline">
                                Don't have an account?&nbsp;&nbsp;
                            </Typography>

                            <Typography fontFamily={"Inter"} fontWeight={600} fontSize={'16px'} color={"#fff"} sx={{ textDecoration: 'underline', textDecorationColor: '#fff', "&:hover": { cursor: "pointer" } }} display="inline" onClick={() => navigate('/signUp')}>
                                Sign Up
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box >
    )
}

export default Login;
const initialState = {
    isOpen: false,
    message: "Loading..."
}
export function LoadingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case "TOGGLE_LOADING":
            return {
                ...state,
                isOpen: payload.flag,
                message: payload?.message ?? state.message
            }
        default:
            return state;
    }
}
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getTeam = async (query) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/teams`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
};

export {
  getTeam
};
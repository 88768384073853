import React from "react";
import {
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Box,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const TableListing = ({ rows }) => {
	return (
		<TableContainer
			component={Paper}
			elevation={0}
			sx={{ backgroundColor: "transparent", color: "white" }}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{ color: "white", borderBottom: "none" }}>
							#
						</TableCell>
						<TableCell sx={{ color: "white", borderBottom: "none" }}>
							Team Member
						</TableCell>
						<TableCell
							align="right"
							sx={{ color: "white", borderBottom: "none" }}
						>
							Points
						</TableCell>
						<TableCell
							sx={{ color: "white", borderBottom: "none" }}
						></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((member) => (
						<TableRow key={member.id}>
							{/* Rank */}
							<TableCell sx={{ color: "white", borderBottom: "none" }}>
								{member.rank}
							</TableCell>

							{/* Team Member info with Avatar */}
							<TableCell
								sx={{ color: "white", borderBottom: "none", padding: 0 }}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Avatar alt={member.name} src={member.avatar} />
									<Typography variant="body1" style={{ marginLeft: 8 }}>
										{member.name}
									</Typography>
								</div>
							</TableCell>

							{/* Points */}
							<TableCell
								align="right"
								sx={{ color: "white", borderBottom: "none" }}
							>
								<Typography variant="body1" fontWeight="bold">
									{member.points}
								</Typography>
							</TableCell>

							<TableCell
								sx={{ color: "white", borderBottom: "none" }}
								align="right"
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<Typography
										fontFamily={"Roboto"}
										fontWeight={300}
										fontSize={"14px"}
										color={"#fff"}
										lineHeight={"24px"}
									>
										points
									</Typography>

									<KeyboardArrowRightIcon />
								</Box>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TableListing;

import { Box, useMediaQuery, useTheme, Typography, IconButton, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { markCompleteAction } from '../actions/chapter';
import { NotificationToaster } from '../utils/notificationToaster';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../assets/lottie/confeti.json';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    // minHeight: isMobile ? 'auto' : '115px',
    paddingBottom: '0px',
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '10px 20px 10px 20px',
    fontSize: '20px',
    fontWeight: 700,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    fontFamily: "Attila Sans",
    lineHeight: '19px',
    textTransform: 'none',
    width: '50%',
    height: "50px",
    marginTop: '10px'
}));

const Quiz = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const chapterDetails = useSelector((state) => state.quiz);
    const isComplete = chapterDetails?.completed;

    const [selected, setSelected] = useState(isComplete ? chapterDetails?.quiz_questions?.[0]?.correct_answer_index : null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [isWrong, setIsWrong] = useState(null);
    const [showConfettie, setShowConfettie] = useState(false);

    const handleMCQOptionSelect = (idx) => {
        setSelected(idx);
    }

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    const handleOnSubmit = () => {
        setIsCorrect(false);
        setIsWrong(false);

        if (selected === chapterDetails?.quiz_questions?.[0]?.correct_answer_index) {
            setIsCorrect(true);

            dispatch(markCompleteAction(chapterDetails?.id))
                .then((res) => {
                    setShowConfettie(true);
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            setIsWrong(true);
        }
    }

    return (
        <>
            {
                showConfettie && (
                    <Lottie 
                        options={defaultOptions}
                        eventListeners={[
                            {
                                eventName: 'complete',
                                callback: () => setShowConfettie(false),
                            }
                        ]}  
                        height={"100%"}
                        width={"100%"}
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 0
                        }}
                    />
                )
            }            
        <Box
            sx={{
                padding: '10px 20px',
                display: 'flex',
                justifyContent: isMobile ? "left" : "center",
                paddingTop: 3,
                zIndex: 1
            }}
        >
            <Grid container direction="column" spacing={3} width={isMobile ? 'wuto' : '700px'}>
                <Grid item>
                    <Box
                        onClick={() => navigate(-1)}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': { cursor: 'pointer' }
                        }}
                    >
                        <IconButton>
                            <ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
                        </IconButton>

                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                            Back
                        </Typography>
                    </Box>
                </Grid>

                {
                    chapterDetails?.quiz_questions?.map((question) => {
                        return (
                            <>
                                <Grid item>
                                    <StyledCard isMobile={isMobile}>
                                        <CardContent>
                                            <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'} mb={2}>
                                                Quiz Question
                                            </Typography>

                                            <Typography fontFamily={"Inter"} fontSize={'16px'} color={"#fff"} lineHeight={'19px'}>
                                                {question?.question}
                                            </Typography>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>

                                <Grid item>
                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'} mb={1} mt={1}>
                                        Answer Options
                                    </Typography>
                                </Grid>

                                {
                                    question?.multiple_choice_answers?.map((ans, key) => {
                                        return (
                                            <Grid item>
                                                <StyledCard isMobile={isMobile} onClick={() => { !isComplete && handleMCQOptionSelect(key) }} style={{ cursor: 'pointer' }}>
                                                    <CardContent>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignContent: 'center',
                                                                gap: "10px",
                                                                opacity: isComplete ? 0.5 : 1,
                                                                pointerEvents: isComplete ? 'none' : 'initial'
                                                            }}
                                                        >
                                                            <Radio
                                                                style={{
                                                                    color: '#bdec5b'
                                                                }}
                                                                checked={selected === key}
                                                            />

                                                            <Typography fontFamily={"Inter"} fontSize={'16px'} color={"#fff"} mt={1.1}>
                                                                {ans}
                                                            </Typography>
                                                        </Box>
                                                    </CardContent>
                                                </StyledCard>
                                            </Grid>
                                        )
                                    })
                                }
                            </>
                        )
                    })
                }

                {
                    (isWrong || isCorrect) && (
                        <>
                            <Grid item>
                                <StyledCard isMobile={isMobile}>
                                    <CardContent>
                                        <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'} mb={2}>
                                        {isWrong? "Incorrect Answer" : "Correct Answer" } 
                                        </Typography>

                                        <Typography fontFamily={"Inter"} fontSize={'16px'} color={"#fff"} lineHeight={'19px'}>
                                            {chapterDetails?.quiz_questions[0]?.correct_answer_explanation}
                                        </Typography>
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        </>
                    )
                }
                <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "center",
                            }}
                        >
                    <StyledButton disabled={isComplete} onClick={() => { handleOnSubmit() }}>
                        Submit
                    </StyledButton>
                    </Box>
                </Grid>
                <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "center",
                            }}
                            onClick={() => navigate("/dashboard")}
                        >
                            <StyledButton isMobile={isMobile}>
                                Back to Dashboard
                            </StyledButton>
                        </Box>
                    </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default Quiz;
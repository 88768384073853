import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const login = async (payload) => {
    const response = await axios.post(`${API_URL}/users/auth`, payload)
    return response;
};

const signUp = async (payload) => {
    const response = await axios.post(`${API_URL}/users/register`, payload)
    return response;
};

const editUser = async (payload, userId) => {
    const token = localStorage.getItem("token");

    const response = await axios.put(`${API_URL}/users/${userId}`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    })
    return response;
};

const getUserDetails = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/users/`, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    })
    return response;
};

const changePassword = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.put(`${API_URL}/users/update_password`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    })
    return response;
};

const logout = () => (dispatch) => {
    localStorage.clear();
    return Promise.resolve();
};

export {
    login,
    logout,
    signUp,
    editUser,
    getUserDetails,
    changePassword
};


export default function QuizReducer(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case "UPDATE_QUIZ":
            return payload;
        case "RESET_QUIZ":
            return null;
        default:
            return state;
    }
}
import Typography from '@mui/material/Typography';

const urlRegexHttp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
const urlRegexwww = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const LinkifyTypography = ({ children, ...props }) => {
  const parseText = (text) => {
    const parts = text.split(" ");
    
    return parts?.map((part, index) => {
      if (urlRegexHttp?.test(part)) {
        const href = part?.startsWith('http') ? part : `http://${part}`;
        
        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer" style={{ color: '#bdec5b', textDecoration: 'none' }}>
            {part}{index !== text?.length && " "}
          </a>
        );
      }

      if (urlRegexwww?.test(part)) {
        const href = part?.startsWith('http') ? part : `http://${part}`;
        
        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer" style={{ color: '#bdec5b', textDecoration: 'none', height: '100px' }}>
            {part}{index !== text?.length && " "}
          </a>
        );
      }

      return <span key={index}>{part}{index !== text?.length && " "}</span>;
    });
  };

  return (
    <Typography {...props}>
      {typeof children === 'string' ? parseText(children) : children}
    </Typography>
  );
};

export default LinkifyTypography;

import { Box, Card, CardContent, Chip, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import StarIcon from '../../../assets/icons/dashboard/star.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlurryPlaceHolder from '../../../assets/placeholderBlurry.png';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : '100%',
    '&:hover': { cursor: 'pointer' }
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const StyledChipBlack = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#000',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const ChallengeCard = ({ challenge, isViewAll = false }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledCard isMobile={isMobile} onClick={isViewAll ? () => { } : () => navigate(`/challenge/${challenge.id}`)}>
            <CardContent style={{ "&:last-child": { paddingBottom: 0 } }}>
                {
                    isViewAll ? (
                        <>
                            <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'} style={{ textDecoration: "underline" }}>
                                View All Guides
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Grid container spacing={2} wrap='nowrap'>
                                <Grid item>
                                    <LazyLoadImage src={challenge?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" width="85px" height="85px" style={{ borderRadius: '12px' }} />
                                </Grid>

                                <Grid item>
                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                        {challenge?.title}
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            marginTop: '6px'
                                        }}
                                    >
                                        <StyledChip label={challenge?.guide_type} />

                                        {
                                            challenge?.themes?.length !== 0 && (
                                                challenge.themes.map((theme) => {
                                                    return (
                                                        <StyledChipBlack label={theme} />
                                                    )
                                                })
                                            )
                                        }
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            marginTop: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                            <img src={StarIcon} alt="" style={{ height: '20px' }} />
                                        </IconButton>

                                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                        {challenge.points_earned} / {challenge.total_points} 
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
            </CardContent>
        </StyledCard>
    )
}

export default ChallengeCard;
import { Box, Grid, useMediaQuery, useTheme, Card, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { getContributorsAction } from '../../actions/landing';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const MissionPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [contributors, setContributors] = useState([]);

    useEffect(() => {
        dispatch(getContributorsAction())
            .then((res) => {
                setContributors(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <Box
            sx={{
                marginTop: '62px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                mb: 10
            }}
        >

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%"
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={400} mb={2}>
                        Our Mission
                    </Typography>

                    <iframe
                        width="100%"
                        height={isMobile ? "220px" : "700px"}
                        src="https://www.youtube.com/embed/jlvqQok0buU?si=TDb5K7SiyXp73p-o"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen>
                    </iframe>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={400} mb={1}>
                        Our team
                    </Typography>

                    <Grid container direction={isMobile ? "column" : "row"} alignItems={"center"} spacing={3} mt={2}>
                        {
                            contributors?.map((con) => {
                                return (
                                    <Grid item xs={2.4} style={{ height: isMobile ? "550px" : "370px" }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={con?.avatar}
                                                alt=""
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '100px',
                                                    marginBottom: '15px'
                                                }}
                                            />

                                            <Typography color="#fff" fontSize={"16px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                                {con?.full_name}
                                            </Typography>

                                            <Typography color="#fff" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={400} mb={1} textAlign={'center'}>
                                                {con?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                </Box>
            </Box>
        </Box >
    )
};

export default MissionPage;
import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	Card,
	Chip,
	CardContent,
	IconButton,
	TextField,
	InputAdornment,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Menu,
	MenuItem,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { toggleLoading } from "../actions/loading";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getSearchResourcesAction,
	getResourceTypesAction,
	getMediaTypesAction,
} from "../actions/search";
import SearchIcon from "@mui/icons-material/Search";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "auto",
	"&:hover": { cursor: "pointer" },
}));

const StyledTextField = styled(TextField)(({ isMobile }) => ({
	"& label": {
		color: "white",
		fontFamily: "Inter",
		fontWeight: 400,
	},
	"& label.Mui-focused": {
		color: "white",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#bdec5b",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#bdec5b",
		},
		"&:hover fieldset": {
			borderColor: "#bdec5b",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#bdec5b",
		},
		backgroundColor: "#FFFFFF1A",
		color: "white",
		height: isMobile ? "45px" : "50px",
		width: isMobile ? "100%" : "700px",
		borderRadius: "40px",
	},
	".MuiFormHelperText-root.Mui-error": {
		color: "red",
	},
	marginBottom: "30px",
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#FF802A",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const StyledChipResource = styled(Chip)(({ isMobile, isSelected }) => ({
	backgroundColor: isSelected ? "#000" : "#FF802A",
	border: isSelected ? "1px solid #fff" : "",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
	cursor: "pointer",
}));

const StyledSelectField = ({ title, checkedItems, setCheckedItems }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChange = (event) => {
		setCheckedItems({
			...checkedItems,
			[event.target.name]: event.target.checked,
		});
	};

	return (
		<Box
			sx={{
				display: "flex",
				height: "35px",
			}}
		>
			<IconButton
				onClick={handleClick}
				size="large"
				sx={{
					border: Object.values(checkedItems).some((value) => value === true)
						? "1px solid #FF802A"
						: "1px solid #808080",
					borderRadius: "20px",
					padding: "10px 15px",
					color: Object.values(checkedItems).some((value) => value === true)
						? "#FF802A"
						: "white",
					backgroundColor: "#000",
				}}
			>
				<FilterListIcon />
				<Typography
					sx={{
						marginLeft: "5px",
						color: Object.values(checkedItems).some((value) => value === true)
							? "#FF802A"
							: "white",
					}}
				>
					{title}
				</Typography>
			</IconButton>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						backgroundColor: "#000",
						color: "white",
						borderRadius: "10px",
						padding: "0px 10px",
						border: "1px solid #808080",
						marginTop: "10px",
					},
				}}
				MenuListProps={{ sx: { py: "5px" } }}
			>
				<FormControl component="fieldset" variant="standard">
					<FormGroup>
						{Object.keys(checkedItems).map((key) => (
							<FormControlLabel
								key={key}
								control={
									<Checkbox
										checked={checkedItems[key]}
										onChange={handleChange}
										name={key}
										sx={{
											color: "#c0c0c0",
											"&.Mui-checked": {
												color: "#FF802A",
											},
											height: "35px",
										}}
									/>
								}
								label={key.charAt(0).toUpperCase() + key.slice(1)}
								sx={{ color: "white", height: "35px" }}
							/>
						))}
					</FormGroup>
				</FormControl>
			</Menu>
		</Box>
	);
};

const Search = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const { searchQuery } = useParams();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const [resources, setResources] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [resourceTypes, setResourceTypes] = useState([]);
	const [mediaTypes, setMediaTypes] = useState([]);

	const handleDataConvert = (arr) => {
		let result = {};

		// eslint-disable-next-line array-callback-return
		arr?.map((itm) => {
			result[itm?.name?.toLowerCase()] = false;
		});

		return result;
	};

	const handleSearch = () => {
		dispatch(getSearchResourcesAction(searchText, resourceTypes, mediaTypes))
			.then((res) => {
				setResources(res.data);
			})
			.catch((err) => {});
	};

	const getResourceTypes = () => {
		dispatch(toggleLoading(true));

		dispatch(getResourceTypesAction())
			.then((res) => {
				setResourceTypes(handleDataConvert(res?.data?.resource_types));
				dispatch(toggleLoading(false));
			})
			.catch((err) => {
				dispatch(toggleLoading(false));
				console.log(err);
			});

		dispatch(getMediaTypesAction())
			.then((res) => {
				setMediaTypes(handleDataConvert(res?.data?.resource_themes));
				dispatch(toggleLoading(false));
			})
			.catch((err) => {
				dispatch(toggleLoading(false));
				console.log(err);
			});
	};

	useEffect(() => {
		getResourceTypes();
	}, []);

	// useEffect(() => {
	// 	handleSearch();
	// }, [selectedResourceType]);

	return (
		<Box
			sx={{
				padding: isMobile ? "24px" : "28px",
				paddingTop: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: isMobile ? "left" : "center",
				alignItems: isMobile ? "left" : "center",
			}}
		>
			<Grid
				container
				direction="column"
				spacing={0}
				width={isMobile ? "auto" : "700px"}
			>
				<Grid item>
					<Box
						onClick={() => navigate("/dashboard")}
						sx={{
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							"&:hover": { cursor: "pointer" },
						}}
					>
						<IconButton>
							<ArrowBackIosIcon
								style={{ color: "#fff", width: "18px", height: "18px" }}
							/>
						</IconButton>

						<Typography
							fontFamily={"Inter"}
							fontWeight={700}
							fontSize={"16px"}
							color={"#fff"}
							lineHeight={"24px"}
						>
							Back
						</Typography>
					</Box>
				</Grid>
			</Grid>

			<Typography
				fontFamily={"Inter"}
				fontWeight={900}
				fontSize={"24px"}
				color={"#fff"}
				mt={2}
				mb={0}
				lineHeight={"29px"}
			>
				Resources
			</Typography>

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignContent: "center",
					gap: "0px",
					marginBottom: 5,
				}}
			>
				<StyledTextField
					isMobile={isMobile}
					margin="normal"
					fullWidth
					name="search"
					placeholder="Search..."
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.keyCode == 13) {
							handleSearch();
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										handleSearch();
									}}
								>
									<SearchIcon style={{ color: "#fff" }} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						width: "100%",
						marginTop: "-10px",
						gap: "10px",
					}}
				>
					<StyledSelectField
						title={"Categories"}
						checkedItems={resourceTypes}
						setCheckedItems={setResourceTypes}
					/>
					<StyledSelectField
						title={"Media Type"}
						checkedItems={mediaTypes}
						setCheckedItems={setMediaTypes}
					/>
					{/* {
                        resourceTypes?.map((rt) => {
                            return (
                                <StyledChipResource label={rt?.name} isSelected={selectedResourceType === rt?.name} onClick={() => setSelectedResourceType(rt?.name)} />
                            )
                        })
                    } */}
				</Box>
			</Box>

			<Grid
				container
				direction="column"
				spacing={2}
				style={{ width: isMobile ? "auto" : "700px" }}
			>
				{resources?.resources?.map((resource) => {
					return (
						<Grid item style={{ width: "100%" }}>
							<a
								href={resource?.url}
								target="_blank"
								style={{ color: "inherit", textDecoration: "inherit" }}
							>
								<StyledCard
									isMobile={isMobile}
									sx={{
										"&:hover": { cursor: "pointer" },
										maxWidth: isMobile ? "auto" : "100%",
									}}
								>
									<CardContent
										style={{
											paddingBottom: "16px",
											"&:last-child": { paddingBottom: "16px" },
										}}
									>
										<Grid container direction={"column"} spacing={2}>
											<Grid item>
												<Typography
													fontFamily={"Roboto"}
													fontWeight={700}
													fontSize={"20px"}
													color={"#fff"}
													lineHeight={"24px"}
												>
													{resource?.title}
												</Typography>
											</Grid>

											<Grid item>
												<Typography
													fontFamily={"Inter"}
													fontWeight={400}
													fontSize={"13px"}
													color={"#fff"}
													lineHeight={"19px"}
												>
													{resource?.description}
												</Typography>
											</Grid>

											<Grid item>
												<StyledChip label={resource?.resource_type} />

												<StyledChip
													style={{
														backgroundColor: "#000",
														marginLeft: "10px",
														border: "1px solid #fff",
													}}
													label={resource?.resource_theme}
												/>
											</Grid>
										</Grid>
									</CardContent>
								</StyledCard>
							</a>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};

export default Search;

import FeedCard from "./FeedCard";
import AddPostCard from "./AddPostCard";
import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostsAction } from "../../actions/posts";
import LikesDialog from "./LikesDialog";
import InfiniteScroll from "react-infinite-scroller";

const StyledButton = styled(Button)(() => ({
	backgroundColor: "#bdec5b",
	padding: "10px 20px",
	fontSize: "15px",
	fontWeight: 600,
	fontFamily: "Attila Sans",
	borderRadius: "100px",
	color: "#000",
	"&:hover": {
		backgroundColor: "#bdec5b",
		color: "#000",
	},
	height: "40px",
	width: "200px",
	marginBottom: 20,
}));

const Feed = ({ newPost, setNewPost, title = null, isUserFeed = false }) => {
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const postsArray = useSelector((state) => state.posts);
	const userId = useSelector((state) => state.auth.user.id);
	const [posts, setPosts] = useState([]);
	const [likesDialogOpen, setLikesDialogOpen] = useState(false);
	const [likesDialogListing, setLikesDialogListing] = useState([]);
	const [isReset, setIsReset] = useState(false);

	let page = useRef(1);
	let totalPosts = useRef(0);

	const handleLikesDialogClose = () => {
		setLikesDialogOpen(false);
	};

	const fetchPosts = () => {
		let id = null;
		if (isUserFeed) {
			id = userId;
		}

		dispatch(getPostsAction(page.current, id))
			.then((res) => {
				totalPosts.current = res;
				setIsReset((prevState) => !prevState);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const resetPosts = () => {
		page.current = 1;
		totalPosts.current = 0;

		fetchPosts();
	};

	useEffect(() => {
		if (postsArray === null) {
			fetchPosts();
		}
	}, []);

	return (
		<>
			<LikesDialog
				isOpen={likesDialogOpen}
				handleClose={handleLikesDialogClose}
				likesListing={likesDialogListing}
			/>

			<Grid
				container
				direction={"column"}
				spacing={0}
				style={{
					width: isMobile ? "100%" : "700px",
					backgroundColor: "#FFFFFF1A",
					borderRadius: "10px",
					padding: "10px 10px",
				}}
			>
				{title && (
					<Grid item>
						<Typography
							fontFamily={"Roboto"}
							fontWeight={600}
							fontSize={"22px"}
							color={"#fff"}
							mt={2}
							mb={1}
							lineHeight={"26px"}
						>
							{title}
						</Typography>
					</Grid>
				)}

				<Grid item>
					<AddPostCard setNewPost={setNewPost} resetPosts={resetPosts} />
				</Grid>

				<Grid item>
					<InfiniteScroll
						scrollThreshold={"20px"}
						loadMore={() => {
							page.current = page.current + 1;
							fetchPosts();
						}}
						hasMore={totalPosts.current > page.current * 10}
						loader={
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "10px",
								}}
							>
								<div style={{ display: "flex", height: "60px", width: "60px" }}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
										<radialGradient
											id="a12"
											cx=".66"
											fx=".66"
											cy=".3125"
											fy=".3125"
											gradientTransform="scale(1.5)"
										>
											<stop offset="0" stop-color="#BDEC5B"></stop>
											<stop
												offset=".3"
												stop-color="#BDEC5B"
												stop-opacity=".9"
											></stop>
											<stop
												offset=".6"
												stop-color="#BDEC5B"
												stop-opacity=".6"
											></stop>
											<stop
												offset=".8"
												stop-color="#BDEC5B"
												stop-opacity=".3"
											></stop>
											<stop
												offset="1"
												stop-color="#BDEC5B"
												stop-opacity="0"
											></stop>
										</radialGradient>
										<circle
											transform-origin="center"
											fill="none"
											stroke="url(#a12)"
											stroke-width="18"
											stroke-linecap="round"
											stroke-dasharray="200 1000"
											stroke-dashoffset="0"
											cx="100"
											cy="100"
											r="70"
										>
											<animateTransform
												type="rotate"
												attributeName="transform"
												calcMode="spline"
												dur="2"
												values="360;0"
												keyTimes="0;1"
												keySplines="0 0 1 1"
												repeatCount="indefinite"
											></animateTransform>
										</circle>
										<circle
											transform-origin="center"
											fill="none"
											opacity=".2"
											stroke="#BDEC5B"
											stroke-width="18"
											stroke-linecap="round"
											cx="100"
											cy="100"
											r="70"
										></circle>
									</svg>
								</div>
							</div>
						}
					>
						<Grid container direction={"column"} spacing={2}>
							{postsArray?.map((post, key) => {
								return (
									<Grid item>
										<FeedCard
											key={key}
											post={post}
											setLikesDialogListing={setLikesDialogListing}
											setLikesDialogOpen={setLikesDialogOpen}
										/>
									</Grid>
								);
							})}
						</Grid>
					</InfiniteScroll>
				</Grid>
			</Grid>
		</>
	);
};

export default Feed;

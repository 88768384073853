import { Box, Button, Grid, useMediaQuery, useTheme, Card, CardContent, styled, Typography, Chip, IconButton, TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import landingCard1 from '../../assets/tempImages/landingCard1.jpg';
import landingCard2 from '../../assets/tempImages/landingCard2.png'
import landingCard3 from '../../assets/tempImages/landingCard3.jpeg';

import l1 from '../../assets/tempImages/l1.jpeg';
import l2 from '../../assets/tempImages/l2.jpg';
import l3 from '../../assets/tempImages/l3.jpeg';
import l4 from '../../assets/tempImages/l4.jpg';

import landing2 from '../../assets/landing/2.jpg';
import landing3 from '../../assets/landing/3.jpg';
import landing4 from '../../assets/landing/4.jpeg';
import landing7 from '../../assets/landing/7.jpg';

import { ReactComponent as LogoWhite } from '../../assets/logo/logo-full-white.svg';
import img6 from '../../assets/landing/6.png';
import InstagramIcon from '@mui/icons-material/Instagram';


import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '12px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    '&:hover': { cursor: 'pointer' },
    width: '90%',
}));

const StyledCardBlack = styled(Card)(({ isMobile }) => ({
    borderRadius: '12px',
    backgroundColor: '#000',
    color: '#fff',
    width: isMobile ? '280px' : '350px',
    height: isMobile ? '300px' : '433px',
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    fontFamily: "Attila Sans",
    lineHeight: '19px'
}));

const StyledButtonGreen = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: 'Attila Sans',
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    width: '85%',
    height: '36px',
    lineHeight: '14px',
    textTransform: 'none'
}));



const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#313440',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#313440',
        },
        '&:hover fieldset': {
            borderColor: '#313440',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#313440',
        },
        backgroundColor: "#242526",
        color: 'white',
        border: '1px solid #313440',
        height: '50px',
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
}));

const Landing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [challanges, setChallanges] = useState([]);
    const [contributors, setContributors] = useState([]);
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        terms: false,
    });

    useEffect(() => {
        // dispatch(getAllGuidesAction())
        //     .then((res) => {
        //         setChallanges(res.data.guide_types.slice(0, 2));
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })

        // dispatch(getContributorsAction())
        //     .then((res) => {
        //         setContributors(res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
    }, []);

    return (
        <Box
            sx={{
                marginTop: '-62px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                mb: 10
            }}
        >
            <Box
                sx={{
                    height: isMobile ? '550px' : '650px',
                }}
            >
                <Swiper
                    allowTouchMove={false}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                    style={{
                        height: isMobile ? '550px' : '650px',
                        zIndex: 1,
                    }}
                >
                    <SwiperSlide className='main-landing-swiper'>
                        <img
                            src={landing7}
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </SwiperSlide>

                    <SwiperSlide className='main-landing-swiper'>
                        <img
                            src={landing2}
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </SwiperSlide>

                    <SwiperSlide className='main-landing-swiper'>
                        <img
                            src={landing3}
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </SwiperSlide>

                    <SwiperSlide className='main-landing-swiper'>
                        <img
                            src={landing4}
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </SwiperSlide>
                </Swiper>

                <Box
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        left: isMobile ? '5%' : '12%',
                        bottom: isMobile ? '27%' : '30%',
                        zIndex: 2,
                        maxWidth: isMobile ? '80%' : '20%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            maxWidth: '100%',
                        }}
                    >

                        <Swiper
                            allowTouchMove={false}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay]}
                            className="mySwiper"
                            style={{
                                marginRight: 0,
                                marginLeft: 7,
                            }}
                        >
                            <SwiperSlide>
                                <Typography color={"#fff"} fontFamily={"Attila Sans"} fontWeight={600} fontSize={"24px"} lineHeight={"28px"}>
                                    Learn
                                </Typography>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Typography color={"#fff"} fontFamily={"Attila Sans"} fontWeight={600} fontSize={"24px"} lineHeight={"28px"}>
                                    Do
                                </Typography>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Typography color={"#fff"} fontFamily={"Attila Sans"} fontWeight={600} fontSize={"24px"} lineHeight={"28px"}>
                                    Share
                                </Typography>
                            </SwiperSlide>
                        </Swiper>

                        <LogoWhite
                            style={{
                                width: '226px',
                                height: '38px'
                            }}
                        />

                        <Typography style={{ marginLeft: 7 }} color={"#fff"} fontFamily={"Inter"} fontWeight={400} fontSize={"16px"}>
                        Building A Culture of Sustainable Thinking
                        </Typography>
                    </Box>
                </Box>

                {
                    !isMobile && (
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'relative',
                                left: '60%',
                                bottom: '70%',
                                zIndex: 2,
                                maxWidth: '40%',
                            }}
                        >
                            <StyledCard style={{ width: '390px', justifyContent: 'center', alignItems: 'center' }}>
                                <CardContent style={{ display: "flex", justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
                                    <Typography style={{ width: "70%" }} textAlign={"center"} color={"#fff"} fontFamily={"Roboto"} fontWeight={700} fontSize={"16px"} lineHeight={"26px"}>
                                        “Because everything is daunting,
                                        but something is easy.”
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                        </Box>
                    )
                }
            </Box>


            {
                isMobile && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <StyledCard>
                            <CardContent>
                                <Typography fontSize={"20px"} lineHeight={"24px"} fontFamily={"Roboto"} fontWeight={700}>
                                    "Because everything is daunting, but something is easy."
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Box>
                )
            }

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%"
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={600} mb={2}>
                        Meet something™
                    </Typography>

                    <iframe
                        width="100%"
                        height={isMobile ? "220px" : "700px"}
                        src="https://www.youtube.com/embed/abs34eOMFNw?si=7lwoOzx4F0j3hVB6"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{ borderRadius: '12px' }}
                    >
                    </iframe>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#FFFFFF1A",
                    paddingBottom: 6
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={600} mb={1}>
                        How it works
                    </Typography>

                    <Grid container spacing={3} direction={isMobile ? "column" : "row"} alignItems={"center"} justifyContent={"center"} mt={isMobile ? 1 : 0}>
                        <Grid item>
                            <StyledCardBlack>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '0px 0px 0px 5px',
                                        }}
                                    >
                                        <img
                                            src={landingCard1}
                                            alt=""
                                            style={{
                                                width: '305px',
                                                height: '200px',
                                                borderRadius: '12px'
                                            }}
                                        />

                                        <Typography mt={2} color="#bdec5b" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                            STEP 1
                                        </Typography>

                                        <Typography mt={2} color="#fff" fontSize={"20px"} lineHeight={"24px"} fontFamily={"Roboto"} fontWeight={600}>
                                            Discovery
                                        </Typography>

                                        <Typography mt={1} color="#fff" fontSize={"15px"} lineHeight={"22px"} fontFamily={"Inter"} fontWeight={400}>
                                            Kicking-off with an inspiring workshop, the first two week sprint is about discovering the climate crisis through a solutions lens. Choose one of our down-to-earth micro-learning guides.
                                        </Typography>

                                    </Box>
                                </CardContent>
                            </StyledCardBlack>
                        </Grid>

                        <Grid item>
                            <StyledCardBlack>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '0px 0px 0px 5px'
                                        }}
                                    >
                                        <img
                                            src={landingCard2}
                                            alt=""
                                            style={{
                                                width: '305px',
                                                height: '200px',
                                                borderRadius: '12px',
                                                objectFit: 'cover'
                                            }}
                                        />

                                        <Typography mt={2} color="#bdec5b" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                            STEP 2
                                        </Typography>

                                        <Typography mt={2} color="#fff" fontSize={"20px"} lineHeight={"24px"} fontFamily={"Roboto"} fontWeight={600}>
                                            Action
                                        </Typography>

                                        <Typography mt={1} color="#fff" fontSize={"15px"} lineHeight={"22px"} fontFamily={"Inter"} fontWeight={400}>
                                            A team check in to discuss progress, and then it's time to choose from our suite of light-touch, behaviour change guides designed to be picked through a lens of joy!
                                        </Typography>

                                    </Box>
                                </CardContent>
                            </StyledCardBlack>
                        </Grid>

                        <Grid item>
                            <StyledCardBlack>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '0px 0px 0px 5px',
                                        }}
                                    >
                                        <img
                                            src={landingCard3}
                                            alt=""
                                            style={{
                                                width: '305px',
                                                height: '200px',
                                                borderRadius: '12px'
                                            }}
                                        />

                                        <Typography mt={2} color="#bdec5b" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                            STEP 3
                                        </Typography>

                                        <Typography mt={2} color="#fff" fontSize={"20px"} lineHeight={"24px"} fontFamily={"Roboto"} fontWeight={600}>
                                            Amplify
                                        </Typography>

                                        <Typography mt={1} color="#fff" fontSize={"15px"} lineHeight={"22px"} fontFamily={"Inter"} fontWeight={400}>
                                            At the end of the program, ideate with your team on how your learnings can inform your impact within your company.
                                        </Typography>

                                    </Box>
                                </CardContent>
                            </StyledCardBlack>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid item mt={1} mb={2}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width:'300px',
                                        marginTop: '40px'
                                    }}
                                >
                                    
                                    <StyledButtonGreen>
                                <a href='mailto:will@wearesomething.io?subject=Request%20a%20Demo%20(from%20web)!' style={{ textDecoration: 'none' }}>
                                    <Typography color="#000" fontSize={"20px"}  fontFamily={"Attila Sans"} fontWeight={600} mb={1}>
                                        Request Demo
                                    </Typography>
                                    </a>
                                        
                                    </StyledButtonGreen>
                                </Box>
                            </Grid>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={600} mb={1}>
                        Community
                    </Typography>

                    <Typography color="#fff" fontSize={"16px"} lineHeight={"29px"} fontFamily={"Inter"} fontWeight={400} mb={2}>
                        Your climate action community. Start small, join others and before you know it you’re part of something™
                    </Typography>

                    <Grid container direction={isMobile ? "column" : "row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    maxWidth: '280px',
                                    maxHeight: '250px',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={l1}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    maxWidth: '280px',
                                    maxHeight: '250px',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={l2}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    maxWidth: '280px',
                                    maxHeight: '250px',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={l3}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    maxWidth: '280px',
                                    maxHeight: '250px',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={l4}
                                    alt=""
                                    style={{
                                        width: '135%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
            </Box>

            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "60%",
                        marginTop: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={400} mb={1}>
                        Challenges
                    </Typography>

                    <Grid container direction={isMobile ? "column" : "row"} spacing={3}>
                        {
                            challanges?.length === 0 ? (
                                <>
                                    <Typography fontFamily={"Roboto"} fontWeight={600} fontSize={'18px'} color={"#fff"} mt={4} ml={2} lineHeight={'26px'}>
                                        No Challanges Found
                                    </Typography>
                                </>
                            ) : (
                                challanges?.map((obj1, key) => {
                                    return (
                                        obj1?.guides?.map((obj, key) => {
                                            return (
                                                <Grid key={obj.id} item xs={isMobile ? 12 : 6}>
                                                    <StyledChallangeCard isMobile={isMobile}>
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid item xs={isMobile ? 4 : 2.75}>
                                                                    <img src={obj.thumbnail} alt="" width="85px" height="auto" style={{ borderRadius: '12px' }} />
                                                                </Grid>

                                                                <Grid item xs={isMobile ? 8 : 8.5}>
                                                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                                        {obj.title}
                                                                    </Typography>

                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: '6px',
                                                                            marginTop: '6px'
                                                                        }}
                                                                    >
                                                                        <StyledChallengeChip label={obj?.guide_type} />

                                                                        {
                                                                            obj?.themes?.length !== 0 && (
                                                                                obj.themes.map((theme) => {
                                                                                    return (
                                                                                        <StyledChallengeChip label={theme} />
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: 'inline-flex',
                                                                            marginTop: '10px',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                                                            <img src={StarIcon} alt="" style={{ height: '20px' }} />
                                                                        </IconButton>

                                                                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                                                            {obj.total_points}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </StyledChallangeCard>
                                                </Grid>
                                            )
                                        })
                                    )
                                })
                            )
                        }
                    </Grid>

                </Box>
            </Box> */}

            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "60%",
                        marginTop: 4
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={400} mb={1}>
                        Our team
                    </Typography>

                    <Grid container direction={isMobile ? "column" : "row"} alignItems={"center"} spacing={3} mt={2}>
                        {
                            contributors?.map((con) => {
                                return (
                                    <Grid item xs={2.4} style={{ height: isMobile ? "550px" : "370px" }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={con?.avatar}
                                                alt=""
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '100px',
                                                    marginBottom: '15px'
                                                }}
                                            />

                                            <Typography color="#fff" fontSize={"16px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                                {con?.full_name}
                                            </Typography>

                                            <Typography color="#fff" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={400} mb={1} textAlign={'center'}>
                                                {con?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }

                        {/* <Grid item xs={2.4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={p2}
                                    alt=""
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '100px',
                                        marginBottom: '15px'
                                    }}
                                />

                                <Typography color="#fff" fontSize={"16px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                    Luc
                                </Typography>

                                <Typography color="#fff" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={400} mb={1} textAlign={'center'}>
                                    [he/him] Luc is a regenerative industrial designer who is passionate about erasing the boundaries between the natural and human-built environment. His superpowers include problem-solving and climbing trees!
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={2.4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={p3}
                                    alt=""
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '100px',
                                        marginBottom: '15px'
                                    }}
                                />

                                <Typography color="#fff" fontSize={"16px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={700}>
                                    Aaron
                                </Typography>

                                <Typography color="#fff" fontSize={"13px"} lineHeight={"19px"} fontFamily={"Inter"} fontWeight={400} mb={1} textAlign={'center'}>
                                    [they/them] Aaron is a human-centered designer and systems thinker driven by a passion for creating accessible, resilient, and inclusive communities. Their superpowers include rapid prototyping and taking wrong turns on hikes.
                                </Typography>
                            </Box>
                        </Grid> */}
            {/* </Grid> */}

            {/* // </Box > */}
            {/* // </Box > */}

            < Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "90%" : "75%",
                        marginTop: 7
                    }}
                >
                    <StyledCard style={{ width: "100%", }}>
                        <CardContent style={{ padding: 0, }}>
                            <Grid container>
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "5px",
                                            justifyContent: "center",
                                            alignItems: "left",
                                            marginLeft: isMobile ? "15px" : "50px",
                                            marginRight: isMobile ? "15px" : "50px",
                                            marginBottom: "20px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={700} mb={1}>
                                            Stay in touch with Something
                                        </Typography>

                                        <Typography color="#fff" fontSize={"16px"} fontFamily={"Inter"} fontWeight={400} mb={2}>
                                            We will send out periodic newsletters to update everyone on the latest. No spam, we promise!
                                        </Typography>

                                        <StyledTextField
                                            name="name"
                                            label="Name"
                                            placeholder=''
                                            fullWidth
                                            value={contactInfo?.name}
                                            onChange={(e) => {
                                                setContactInfo({
                                                    name: e.target.value,
                                                    email: contactInfo.email,
                                                    terms: contactInfo.terms
                                                }
                                                )
                                            }}
                                            style={{ marginBottom: 10 }}
                                        />

                                        <StyledTextField
                                            name="email"
                                            label="Email"
                                            placeholder=''
                                            fullWidth
                                            value={contactInfo?.email}
                                            onChange={(e) => {
                                                setContactInfo({
                                                    name: contactInfo.name,
                                                    email: e.target.value,
                                                    terms: contactInfo.terms
                                                }
                                                )
                                            }}
                                            style={{ marginBottom: 5 }}
                                        />

                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "#fff",
                                                            // '&.Mui-checked': {
                                                            //     color: "#000",
                                                            // },
                                                        }}
                                                    />
                                                }
                                                label="Accept GDPR"
                                            />
                                        </FormGroup>

                                        <StyledButton style={{ width: '40%', padding: "15px 0px 15px 0px", }}>
                                            Signup
                                        </StyledButton>

                                    </Box>
                                </Grid>

                                {
                                    !isMobile && (
                                        <Grid item xs={6}>
                                            <img
                                                src={img6}
                                                alt=""
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </CardContent>
                    </StyledCard>
                </Box>
            </Box >

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: isMobile ? "90%" : "60%",
                        marginTop: isMobile ? 5 : 7,
                        justifyContent: "center"
                    }}
                >
                    <Typography color="#fff" fontSize={"24px"} lineHeight={"29px"} fontFamily={"Attila Sans"} fontWeight={700}>
                        Get in touch
                    </Typography>

                    <a href='mailto:will@wearesomething.io?subject=Interested%20in%20Something%20(from%20web)!'>
                        <Typography color="#fff" fontSize={"16px"} lineHeight={"29px"} fontFamily={"Inter"} fontWeight={400} style={{ textDecoration: "underline", cursor: "pointer" }}>
                            Email Us Now
                        </Typography>
                    </a>
                    <a href='https://www.instagram.com/wearesomething.io/' target="_blank" rel="noopener noreferrer">
                        <InstagramIcon style={{ color: "#fff", fontSize: 90, marginTop: 10, cursor: "pointer" }} />
                    </a>
                </Box>
            </Box>
        </Box >
    )
};

export default Landing;
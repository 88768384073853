export default function PostReducer(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case "GET_POSTS_SUCCESS":
            return payload;
        case "UPDATE_POSTS":
            return payload;
        case "GET_POSTS_ERROR":
            return {
                state
            };
        case "RESET_POSTS":
            return null;
        default:
            return state;
    }
}
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getTeamActiveChallanges = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/teams/active_challenges?page=1&per_page=50`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
};

export {
    getTeamActiveChallanges
}
import { Box, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Stories from 'react-insta-stories';
import { useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import Divider from '@mui/material/Divider';

const styles = {
    seeMore: {
        height: "10vh",
        background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        bottom: 0
    },
    seeMoreExpanded: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        zIndex: 99999
    },
    seeMoreText: {
        color: "white",
        textAlign: "center",
        letterSpacing: "0.1em",
        marginBottom: "2.2vh",
        textTransform: "capitalize",
        opacity: "1",
        fontSize: "0.8em",
        transition: "opacity 300ms ease-in-out"
    },
    seeMoreIcon: {
        color: "white",
        textAlign: "center",
        letterSpacing: "0.2em",
        marginBottom: "0.4vh",
        opacity: "1",
        filter: "drop-shadow(0 0 5px black)",
        textTransform: "capitalize",
        transition: "opacity 300ms ease-in-out"
    },
    seeMoreClose: {
        position: "absolute",
        filter: "drop-shadow(0 3px 2px #ccc)",
        right: "0.5rem",
        top: "0.5rem",
        fontSize: "1.5rem",
        opacity: "0.7",
        padding: "1rem"
    }
}

const PDFStories = ({ imageArray }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [imgArr, setImgArray] = useState(
        [
            {
                url: 'https://wearesomething.s3.eu-north-1.amazonaws.com/bkjka7f4fvh7c54tpzu1trowyqyt?response-content-disposition=inline%3B%20filename%3D%22Guide-31-7.jpg%22%3B%20filename%2A%3DUTF-8%27%27Guide-31-7.jpg\u0026response-content-type=image%2Fjpeg\u0026X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIAW3MD76DUUL3APBPE%2F20240721%2Feu-north-1%2Fs3%2Faws4_request\u0026X-Amz-Date=20240721T153121Z\u0026X-Amz-Expires=300\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=3012549a59a4521ac6a3413526dfa0af59855048226c550a5bd266d3cd3382c8',
                duration: 2000,
                seeMore: ({ close }) => {
                    return (
                        <div style={{ height: '100%', width: '100%', backgroundColor: '#000' }} onClick={close} >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '1px solid #fff',
                                    height: '99%'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        minWidth: '100%',
                                    }}
                                >
                                    <CloseIcon style={{ backgroundColor: '#000', color: '#fff', height: '40px', width: '40px', cursor: 'pointer' }} onClick={close} />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: 4
                                    }}
                                >
                                    <p style={{ color: '#fff' }}><b>Label:</b> Label</p>
                                </Box>
                            </Box>
                        </div >
                    )
                },
            }
        ]
    );

    useEffect(() => {
        let iArr = [];

        imageArray?.map((imgInfo) => {
            const item = {
                url: imgInfo?.url,
                duration: 30000
            };
            if (imgInfo?.label) {
                item.seeMoreCollapsed = ({ toggleMore, action }) => {
                    return (
                        <div style={styles.seeMore} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleMore(true)
                        }} >
                            <span style={styles.seeMoreIcon}>⌃</span>
                            <span style={styles.seeMoreText}>Links</span>
                        </div>
                    )
                }
                item.seeMore = ({ close }) => {
                    return (
                        <div style={{ height: '100%', width: '100%', backgroundColor: '#000' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // border: '1px solid #fff',
                                    height: '99%'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        minWidth: '100%',
                                    }}
                                >
                                    <CloseIcon style={{ backgroundColor: '#000', color: '#fff', height: '40px', width: '40px', cursor: 'pointer' }} onClick={close} />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                            width: '0.4em'
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#bbb',
                                            outline: '1px solid slategrey'
                                        }
                                    }}
                                >
                                    <Box sx={{ paddingLeft: 2, marginTop: -2 }}>
                                        <p style={{ color: '#fff', fontFamily: 'Roboto', fontSize: '20px' }}><b>{imgInfo?.label}</b></p>
                                    </Box>

                                    {
                                        imgInfo?.resource_links?.map((resource, key) => {
                                            return (
                                                <>
                                                    <a style={{ textDecoration: 'none', fontFamily: 'Roboto', fontSize: '18px' }} href={resource?.url} target={"_blank"}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: '10px',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                borderBottom: '2px solid #fff',
                                                                borderTop: key === 0 ? '2px solid #fff' : '',
                                                                paddingLeft: 2,
                                                                paddingRight: 2,
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <p style={{ color: '#fff', cursor: 'pointer' }}>{key + 1}. {resource?.title}</p>
                                                            <LaunchIcon style={{ color: '#fff' }} />
                                                        </Box>
                                                    </a>
                                                </>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        </div>
                    )
                }
            }
            iArr.push(item);
        });

        setImgArray(iArr);
    }, [])

    return (
        <>
            {
                isMobile ? (
                    <>
                        <Stories
                            stories={imgArr}
                            defaultInterval={30000}
                            width={'100%'}
                            height={'100%'}
                            loop={true}
                        />
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // marginBottom: '10%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '500px',
                                }}
                            >
                                <Stories
                                    stories={imgArr}
                                    defaultInterval={30000}
                                    width={'500px'}
                                    height={'900px'}
                                    loop={true}
                                />
                            </Box>
                        </Box>
                    </>
                )
            }
        </>
    )
}

export default PDFStories;
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getChallanges = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/user_enrollments`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
    return response;
}

const addReview = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/reviews`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
}

const getGuides = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/guides`)
    return response;
}

const enrollGuide = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/user_enrollments`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
}

const getChallangeInfo = async (guideId) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/guides/${guideId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
}

const unenrollGuide = async (guideId) => {
    const token = localStorage.getItem("token");

    const response = await axios.delete(`${API_URL}/user_enrollments/${guideId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
}

const checkIn = async (checkinId) => {
    const token = localStorage.getItem("token");

    const payload = {
        checked_in: true
    }
    const response = await axios.put(`${API_URL}/checkins/${checkinId}`, payload , {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
}

export {
    getChallanges,
    addReview,
    getGuides,
    enrollGuide,
    getChallangeInfo,
    unenrollGuide,
    checkIn
}
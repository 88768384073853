import { Backdrop, Paper, Typography } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";

export const Loading = ({
    custom,
    fullScreen,
    message,
    open,
}) => {
    return (
        <Backdrop
            style={{
                position: "fixed",
                zIndex: 1400,
            }}
            open={open}
        >
            {custom ? (
                custom
            ) : (
                <Paper
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        maxWidth: "60%",
                        minHeight: "150px",
                        minWidth: "300px",
                        padding: "16px",
                        backgroundColor: "#1d1d1d",
                        border: "1px solid #fff"
                    }}
                    elevation={0}
                >
                    <LoadingSpinner />
                    {message && (
                        <Typography
                            style={{
                                margin: "0 0 10px 0",
                                color: "#fff"
                            }}
                            variant="h6"
                        >
                            {message}
                        </Typography>
                    )}
                    {/*<LinearProgress className={classes.progress} variant="query"/>*/}
                </Paper>
            )}
        </Backdrop>
    );
};

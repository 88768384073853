import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FireIcon from "../../../assets/icons/dashboard/fire.svg";
import StarIcon from "../../../assets/icons/dashboard/star.svg";
import StarGreenIcon from "../../../assets/icons/dashboard/start-green.png";
import CarIcon from "../../../assets/icons/dashboard/car.svg";
import DashboardCard from "./DashboardCard";
import { getUserDetailsAction } from "../../../actions/auth";
import { Modal, Box, Typography, Card, CardContent } from "@mui/material";
import { getTeamAction } from "../../../actions/team";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import TeamIcon from "../../../assets/icons/dashboard/team.png";
import { useNavigate } from "react-router-dom";

const DashboardCardsComponent = ({ isMobile, scrollToSection }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userDetails = useSelector((state) => state.auth.user);
	const dashboardRefresh = useSelector((state) => state.dashboardRefresh);
	const [modalOpen, setModalOpen] = useState(false);
	const [team, setTeam] = useState([]);
	const [alignment, setAlignment] = useState("first");
	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);
	useEffect(() => {
		dispatch(getUserDetailsAction())
			.then((res) => {
				// console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [dashboardRefresh]);

	useEffect(() => {
		dispatch(getTeamAction())
			.then((res) => {
				setTeam(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment);
		}
	};

	const gridItems = [
		{ label: "Your Score", icon: StarIcon, value: userDetails?.score },
		{ label: "Daily Streak", icon: FireIcon, value: userDetails?.streak || 0 },
		{
			label: "Miles Saved",
			icon: CarIcon,
			value: userDetails?.carbon?.toFixed(2),
			condition: team?.team?.days_to_unlock >= 14,
			onClick: handleOpen,
		},
		{
			label: "Team Score",
			icon: StarIcon,
			value: `${userDetails?.team_score}`,
		},
		{ label: "Target", icon: StarIcon, value: `${userDetails?.target}` },
	];

	const itemsToShow =
		alignment === "first" ? gridItems.slice(0, 3) : gridItems.slice(-2);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{ height: "100px", marginBottom: 2 }} // Adjust height and margin as needed
			>
				<ToggleButtonGroup
					value={alignment}
					exclusive
					onChange={handleAlignment}
					aria-label="grid item selection"
					sx={{ marginBottom: 2 }}
				>
					<ToggleButton
						value="first"
						aria-label="first items"
						selected={alignment === "first"}
						style={{
							color: alignment === "first" ? "#FFD700" : "#B0B0B0", // Yellow if selected, Grey if not
							borderColor: alignment === "first" ? "#FFD700" : "#B0B0B0",
						}}
					>
						{/* <img src={StarIcon} alt="First Items" width={24} height={24} /> */}
						<PersonIcon />
					</ToggleButton>
					<ToggleButton
						value="last"
						aria-label="last items"
						selected={alignment === "last"}
						style={{
							color: alignment === "last" ? "#FFD700" : "#B0B0B0", // Yellow if selected, Grey if not
							borderColor: alignment === "last" ? "#FFD700" : "#B0B0B0",
						}}
					>
						{/* <img src={FireIcon} alt="Last Items" width={24} height={24} /> */}
						<GroupIcon />
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Grid container direction={isMobile ? "column" : "row"}>
				<Grid container spacing={isMobile ? 2 : 4}>
					{itemsToShow.map((item, index) =>
						item.condition !== undefined ? (
							item.condition && (
								<Grid
									item
									xs={isMobile ? 6 : 3}
									key={index}
									style={{ cursor: "pointer" }}
								>
									<DashboardCard
										label={item.label}
										icon={item.icon}
										value={item.value}
										onClick={item.onClick}
									/>
								</Grid>
							)
						) : (
							<Grid item xs={isMobile ? 6 : 3} key={index}>
								<DashboardCard
									label={item.label}
									icon={item.icon}
									value={item.value}
								/>
							</Grid>
						)
					)}

					{alignment !== "first" && (
						<Grid item xs={isMobile ? 6 : 3} style={{ cursor: "pointer" }}>
							<DashboardCard
								label={"View Leaderboard"}
								// icon={item.icon}
								// value={item.value}
								onClick={() => navigate("/teamLeaderboard")}
							/>
						</Grid>
					)}
					<Modal
						open={modalOpen}
						onClose={handleClose}
						aria-labelledby="modal-title"
						aria-describedby="modal-description"
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							width={{ xs: "80%", sm: "60%", md: "40%" }}
							bgcolor="background.paper"
							p={4}
							boxShadow={24}
							borderRadius={1}
						>
							<Typography id="modal-title" variant="h6">
								Miles Saved
							</Typography>
							<Typography id="modal-description" sx={{ mt: 2 }}>
								The equivalent Carbon Dioxide saved displayed as miles not
								driven in a conventional gas car.
							</Typography>
						</Box>
					</Modal>
					{isMobile && (
						<Grid item xs={isMobile ? 6 : 3}>
							<DashboardCard
								label="Team Updates"
								icon={TeamIcon}
								isPost
								scrollToSection={scrollToSection}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default DashboardCardsComponent;

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Card, CardContent, Chip, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme, FormControlLabel, FormGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import StarIcon from '../../assets/icons/dashboard/star.svg';
import { getChallangeInfoAction } from '../../actions/challange';
import ReactStars from "react-rating-stars-component";
import { addChallangeReviewAction, unenrollGuideAction, checkInAction } from '../../actions/challange';
import LockIcon from '@mui/icons-material/Lock';
import PDFStories from '../../components/PDFStories';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlurryPlaceHolder from '../../assets/placeholderBlurry.png';
import BlurryChapterPlaceHolder from '../../assets/placeholderChapterBlurry.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Checkbox from '@mui/material/Checkbox';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/confeti.json';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : '115px',
    paddingBottom: '0px',
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '10px 20px 10px 20px',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    fontFamily: "Attila Sans",
    lineHeight: '19px'
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const StyledChipBlack = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#000',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "#242526",
        color: '#fff',
        border: 'none',
    },
}));

const ChapterCard = ({ chapter }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledCard isMobile={isMobile} style={{ minHeight: '160px' }} sx={{ '&:hover': { cursor: 'pointer' } }} onClick={chapter?.locked ? () => { } : () => { navigate(`/chapter/${chapter?.id}`) }}>
            <CardContent>
                <Grid container spacing={3} wrap="noWrap">
                    <Grid item>
                        {/* <img src={chapter?.thumbnail} alt="" width="95px" height="auto" style={{ borderRadius: '12px' }} /> */}
                        <LazyLoadImage src={chapter?.thumbnail} placeholderSrc={BlurryChapterPlaceHolder} alt="" width="95px" height="95px" style={{ borderRadius: '12px' }} effect="blur" />
                    </Grid>

                    <Grid item xs>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                {chapter?.title}
                            </Typography>
                            {chapter?.locked && (
                                <LockIcon />
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                marginTop: '10px'
                            }}
                        >
                            {/* {
                                chapter.chips.map((chip, key) => {
                                    return (
                                        <StyledChip label={chip} />
                                    )
                                })
                            } */}
                            <StyledChip label={chapter?.theme} />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                marginTop: '16px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                <img src={StarIcon} alt="" style={{ height: '20px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                            {chapter?.completed ? chapter?.points : 0}/{chapter?.points}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    )
}

const ArticleCard = ({ article }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <a href={article?.url} target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <StyledCard isMobile={isMobile} style={{ minHeight: '100%' }} sx={{ '&:hover': { cursor: 'pointer' } }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                {article.title}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'13px'} color={"#fff"} lineHeight={'19px'}>
                                {article.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </StyledCard>
        </a>
    )
}

const Challange = () => {
    const navigate = useNavigate();
    const { challangeId } = useParams();

    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    const [challangeData, setChallangeData] = useState(null);
    const [reviewMessage, setReviewMessage] = useState("");
    const [reviewRating, setReviewRating] = useState(-1);
    const [reviewError, setReviewError] = useState(null);
    const [showReviews, setShowReviews] = useState(false);
    const [showAllResources, setShowAllResources] = useState(false);
    const [showConfettie, setShowConfettie] = useState(false);

    const ratingChanged = (newRating) => {
        setReviewRating(newRating);
    };

    const handleUnenrollGuide = () => {
        dispatch(unenrollGuideAction(challangeData?.id))
            .then((res) => {
                navigate("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleAddReview = () => {
        setReviewError(null);
        if (reviewRating !== -1) {
            dispatch(addChallangeReviewAction(challangeId, reviewMessage, reviewRating))
                .then((res) => {
                    setReviewMessage("");
                    ratingChanged(-1);

                    let prevReviewLst = challangeData.reviews;
                    console.log(prevReviewLst);
                    console.log(res.data.review);
                    prevReviewLst.push(res.data.review)

                    const updatedChallangeData = {
                        ...challangeData,
                        reviews: prevReviewLst,
                    }

                    console.log(updatedChallangeData);

                    // setChallangeData(updatedChallangeData);
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            setReviewError("Please select a rating to add a review");
        }
    }

    const getDateTime = (dateTime) => {
        const today = new Date(dateTime);
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        const hour = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');

        return `${dd}/${mm}/${yyyy} ${hour}:${minutes}`;
    }

    
    const handleCheckboxChange = (checkInId) => (event) => {
        dispatch(checkInAction(checkInId))
            .then((res) => {
                setChallangeData(res.data.guide);  // Update state with new data
                setShowConfettie(true);  // Show confetti effect
            })
            .catch((err) => {
                console.log(err);  // Log any errors
                // Optionally, you can add error handling logic here
            });
    };

    useEffect(() => {
        dispatch(getChallangeInfoAction(challangeId))
            .then((res) => {
                setChallangeData(res.data.guides)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <>
            {
                showConfettie && (
                    <Lottie 
                        options={defaultOptions}
                        eventListeners={[
                            {
                                eventName: 'complete',
                                callback: () => setShowConfettie(false),
                            }
                        ]}  
                        height={"100%"}
                        width={"100%"}
                        style={{
                            position: 'fixed',
                            top: 0,
                            zIndex: 0
                        }}
                    />
                )
            }

            <Box
                sx={{
                    padding: '10px 20px',
                    display: 'flex',
                    justifyContent: isMobile ? "left" : "center",
                    paddingTop: 3,
                    zIndex: 2
                }}
            >
                <Grid container direction="column" spacing={3} width={isMobile ? 'auto' : '700px'}>
                    <Grid item>
                        <Box
                            onClick={() => navigate("/dashboard")}
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': { cursor: 'pointer' }
                            }}
                        >
                            <IconButton>
                                <ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                                Back
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <StyledCard isMobile={isMobile}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {/* <img src={challangeData?.thumbnail} alt="" width="360px" height="320px" style={{ borderRadius: '12px' }} loading="lazy" /> */}
                                            <LazyLoadImage src={challangeData?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" style={{ maxWidth: isMobile ? '320px' : '360px', borderRadius: '12px', maxHeight: 'auto', }} effect="blur" />
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '6px',
                                                marginTop: '6px'
                                            }}
                                        >
                                            <StyledChip label={challangeData?.guide_type} />
                                            {
                                                challangeData?.themes?.map((theme, key) => {
                                                    return (
                                                        <StyledChipBlack key={key} label={theme} />
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'}>
                                            {challangeData?.title}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography fontFamily={"Inter"} fontSize={'13px'} color={"#fff"} lineHeight={'19px'}>
                                            {challangeData?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid item>
                        <StyledCard isMobile={isMobile} style={{ minHeight: "auto" }}>
                            <CardContent>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                    {challangeData?.title}
                                </Typography>

                                <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                                    {challangeData?.points_earned} / {challangeData?.total_points}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    {
                        challangeData?.trailer && (
                            <Grid item>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Trailer
                                </Typography>

                                <StyledCard isMobile={isMobile}>
                                    <CardContent>
                                        <iframe
                                            width="100%"
                                            height={isMobile ? "200px" : "350px"}
                                            src={challangeData?.trailer}
                                            title="YouTube video player"
                                            frameBorder={0}
                                            allowFullScreen>
                                        </iframe>

                                        {/* <video width="100%" height={isMobile ? "200px" : "350px"} src="https://www.youtube.com/watch?v=Vf3-s_YLgWE" controls autoPlay /> */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        )
                    }

                    {
                        challangeData?.chapters?.length !== 0 && (
                            <Grid item>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Chapters
                                </Typography>

                                <Grid container direction="column" spacing={2}>
                                    {
                                        challangeData?.chapters.map((chapter, key) => {
                                            return (
                                                <Grid item>
                                                    <ChapterCard key={key} chapter={chapter} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    }

                    {
                        challangeData?.resource_guide_images && challangeData?.resource_guide_images?.length !== 0 && (
                            <Grid item mb={4} mt={1}>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={2}>
                                    Your {challangeData?.guide_type} Guide
                                </Typography>

                                <PDFStories imageArray={challangeData?.resource_guide_images} />
                            </Grid>
                        )
                    }

                    {
                        challangeData?.checkins?.length > 0 && (
                            <Grid item>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Check In
                                </Typography>

                                <Grid container spacing={2}>
                                    {
                                        challangeData?.checkins?.map((checkIn, index) => {
                                            return (
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            label={(checkIn?.text || 'Day ') + (+index + 1)}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        // "& .MuiSvgIcon-root": {
                                                                        //     fill: "#bdec5b",
                                                                        // },
                                                                        color: '#bdec5b',
                                                                        '&.Mui-checked': {
                                                                            color: '#bdec5b',
                                                                        },
                                                                        '&.Mui-disabled': {
                                                                            color: 'gray',
                                                                            fill: 'gray'
                                                                        },
                                                                        width: '50px',

                                                                    }}
                                                                    checked={checkIn?.checked_in}
                                                                    onChange={handleCheckboxChange(checkIn?.id)}
                                                                    disabled={ checkIn?.locked}
                                                                />
                                                            }
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    color: '#fff',
                                                                    '&.Mui-disabled': {
                                                                        color: 'gray',
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    }

                    {
                        challangeData?.resources.length !== 0 && (
                            <Grid item mt={isMobile ? 0 : 2}>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Dive Deeper
                                </Typography>

                                <Grid container direction="column" spacing={2}>
                                    {showAllResources ? (
                                        <>
                                            {
                                                challangeData?.resources.map((article, key) => {
                                                    return (
                                                        <Grid item>
                                                            <ArticleCard key={key} article={article} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                challangeData?.resources?.slice(0, 3)?.map((article, key) => {
                                                    return (
                                                        <Grid item>
                                                            <ArticleCard key={key} article={article} />
                                                        </Grid>
                                                    )
                                                })
                                            }

                                            {
                                                challangeData?.resources?.length > 3 && (
                                                    <Grid item>
                                                        <Button onClick={() => { setShowAllResources(true) }} style={{ width: '100%', backgroundColor: '#FFFFFF1A', borderRadius: '8px', color: '#fff', paddingTop: '20px', paddingBottom: '10px' }}>
                                                            Show More
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        )
                    }

                    <Grid item>
                        <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={2}>
                            Leave a review
                        </Typography>

                        {/* <StyledButton>
                            Write a Review
                        </StyledButton> */}

                        <StyledCard>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '7px',
                                        alignItems: 'end',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <Typography fontFamily={"Roboto"} fontWeight={500} fontSize={'16px'} color={"#fff"} lineHeight={'26px'} display="inline">
                                        Rating:
                                    </Typography>

                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        activeColor="#ffd700"
                                        value={reviewRating}
                                    />
                                </Box>

                                <StyledTextField
                                    multiline
                                    minRows={1}
                                    maxRows={5}
                                    fullWidth
                                    placeholder="Your review message..."
                                    sx={{
                                        border: 'none',
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={reviewMessage}
                                    onChange={(e) => setReviewMessage(e.target.value)}
                                    error={reviewError}
                                    helperText={reviewError}
                                />

                                <Box
                                    mt={2}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}>
                                    <StyledButton onClick={handleAddReview}>
                                        Add Review
                                    </StyledButton>
                                </Box>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid container item direction={"column"} spacing={2}>

                        {
                            showReviews ? (
                                <>
                                    {
                                        challangeData?.reviews?.map((review, key) => {
                                            return (
                                                <Grid key={key} item>
                                                    <StyledCard isMobile={isMobile}>
                                                        <CardContent>
                                                            <Grid container spacing={1} wrap="noWrap">
                                                                <Grid item>
                                                                    {
                                                                        review?.user?.avatar_url ? (
                                                                            <LazyLoadImage
                                                                                src={review?.user?.avatar_url}
                                                                                alt=""
                                                                                style={{
                                                                                    color: "#fff",
                                                                                    width: "42px",
                                                                                    height: "42px",
                                                                                    borderRadius: "100px",
                                                                                }}
                                                                                effect="blur"
                                                                            />
                                                                        ) : (
                                                                            <AccountCircleIcon sx={{ color: "#fff", width: "50px", height: "50px" }} />
                                                                        )}
                                                                </Grid>

                                                                <Grid container spacing={0} item direction={'column'}>
                                                                    <Grid item>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography color="#bdec5b" style={{ marginRight: "10px" }} fontFamily={'Roboto'} fontSize={'20px'} fontWeight={700} textTransform={'capitalize'} display="inline">
                                                                                {review?.user?.first_name} {review?.user?.last_name}
                                                                            </Typography>

                                                                            <Typography mt={0.4} fontFamily={'Roboto'} fontSize={'12px'} fontWeight={400} color="#aaa" textTransform={'capitalize'} display="inline">
                                                                                {getDateTime(review?.created_at)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item style={{ marginTop: -4 }}>
                                                                        <ReactStars
                                                                            count={5}
                                                                            size={20}
                                                                            edit={false}
                                                                            activeColor="#ffd700"
                                                                            value={review?.rating}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Typography fontFamily={'Inter'} fontSize={'16px'} fontWeight={400} lineHeight={"26px"} color={"#e4e6eb"}>
                                                                            {review?.message}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </StyledCard >
                                                </Grid>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        challangeData?.reviews?.slice(0, 3)?.map((review, key) => {
                                            return (
                                                <Grid key={key} item>
                                                    <StyledCard isMobile={isMobile}>
                                                        <CardContent>
                                                            <Grid container spacing={1} wrap="noWrap">
                                                                <Grid item>
                                                                    {
                                                                        review?.user?.avatar_url ? (
                                                                            <LazyLoadImage
                                                                                src={review?.user?.avatar_url}
                                                                                alt=""
                                                                                style={{
                                                                                    color: "#fff",
                                                                                    width: "42px",
                                                                                    height: "42px",
                                                                                    borderRadius: "100px",
                                                                                }}
                                                                                effect="blur"
                                                                            />
                                                                        ) : (
                                                                            <AccountCircleIcon sx={{ color: "#fff", width: "50px", height: "50px" }} />
                                                                        )}
                                                                </Grid>

                                                                <Grid container spacing={0} item direction={'column'}>
                                                                    <Grid item>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography color="#bdec5b" style={{ marginRight: "10px" }} fontFamily={'Roboto'} fontSize={'20px'} fontWeight={700} textTransform={'capitalize'} display="inline">
                                                                                {review?.user?.first_name} {review?.user?.last_name}
                                                                            </Typography>

                                                                            <Typography mt={0.4} fontFamily={'Roboto'} fontSize={'12px'} fontWeight={400} color="#aaa" textTransform={'capitalize'} display="inline">
                                                                                {getDateTime(review?.created_at)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item style={{ marginTop: -4 }}>
                                                                        <ReactStars
                                                                            count={5}
                                                                            size={20}
                                                                            edit={false}
                                                                            activeColor="#ffd700"
                                                                            value={review?.rating}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Typography fontFamily={'Inter'} fontSize={'16px'} fontWeight={400} lineHeight={"26px"} color={"#e4e6eb"}>
                                                                            {review?.message}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </StyledCard >
                                                </Grid>
                                            )
                                        })
                                    }

                                    {
                                        challangeData?.reviews?.length > 3 && (
                                            <Grid item>
                                                <Button onClick={() => { setShowReviews(true) }} style={{ width: '100%', backgroundColor: '#FFFFFF1A', borderRadius: '8px', color: '#fff', paddingTop: '20px', paddingBottom: '10px' }}>
                                                    Show More
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                </>
                            )
                        }
                    </Grid>

                    <Grid item mt={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "center",
                            }}
                        >
                            <StyledButton
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#000',
                                    border: '#fff solid 1px',
                                }}
                                isMobile={isMobile}
                                onClick={handleUnenrollGuide}
                            >
                                Unenroll Guide
                            </StyledButton>
                        </Box>
                    </Grid>

                    <Grid item>

                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

export default Challange;
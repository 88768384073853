import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Card, CardContent, Chip, Grid, Divider, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StarIcon from '../../assets/icons/dashboard/star.svg';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { useDispatch } from 'react-redux';
import { addPostAction } from '../../actions/posts';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import InstagramIcon from '@mui/icons-material/Instagram';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '10px',
    backgroundColor: '#000000',
    color: '#fff',
    minHeight: isMobile ? 'auto' : 'auto'
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const StyledButtonGreen = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: 'Attila Sans',
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    width: '85%',
    height: '36px',
    lineHeight: '14px',
    textTransform: 'none'
}));

const StyledButtonBlack = styled(Button)(() => ({
    backgroundColor: '#000',
    padding: '15px 30px 15px 30px',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: 'Attila Sans',
    borderRadius: '100px',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#000',
        color: '#fff',
    },
    border: '1px solid #fff',
    width: '95%',
    height: '36px',
    lineHeight: '14px',
    textTransform: 'none'
}));

const StyledTextField = styled(TextField)(() => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "#505050",
        color: '#fff',
        border: '2px solid #c0c0c0',
        borderRadius: '50px',
        height: '45px'
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddPostCard = ({ setNewPost, resetPosts }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const userImage = useSelector((state) => state?.auth.user?.avatar_url);

    const [message, setMessage] = useState("");
    const [postType, setPostType] = useState("text");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFocus, setIsFocus] = useState(false);

    const handleCreateNewPost = () => {
        if (message !== "" || selectedFile !== null) {
            let postTypeAPI = postType;
            if (selectedFile && selectedFile['type']?.includes('video')) {
                postTypeAPI = "video";
            }
            if (!selectedFile) {
                postTypeAPI = "text";
            }
            dispatch(addPostAction(message, selectedFile, postTypeAPI))
                .then((res) => {
                    resetPosts();
                    setMessage("");
                    setSelectedFile(null);
                    setNewPost(false);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        setPostType("text")
    }

    return (
        <StyledCard isMobile={isMobile} style={{ marginBottom: '20px' }}>
            <CardContent sx={{ padding: '0px', "&:last-child": { paddingBottom: 0 } }}>

                <Grid container direction={'column'} spacing={1}>

                    {
                        isFocus && (
                            <Grid container item style={{ borderBottom: '1px solid #3a3b3c', paddingTop: '20px', paddingBottom: '15px', paddingLeft: '20px' }}>
                                <Grid item xs={isMobile ? 1.2 : 0.9}>
                                    <IconButton onClick={() => { setPostType("text") }} style={{ width: '28px', height: '28px', backgroundColor: postType === "text" ? '#bdec5b' : 'transparent', color: postType === "text" ? '#000' : '#fff' }}>
                                        <ModeCommentIcon style={{ color: postType === "text" ? '#000' : '#fff', width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={isMobile ? 1.2 : 0.9}>
                                    <IconButton onClick={() => { setPostType("photo") }} style={{ width: '28px', height: '28px', backgroundColor: postType === "photo" ? '#bdec5b' : 'transparent' }}>
                                        <Button
                                            sx={{
                                                color: '#fff',
                                                backgroundColor: 'transparent',
                                                padding: 0,
                                                margin: 0,
                                                border: 'none',
                                                boxShadow: 'none',
                                                "&:hover": {
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                }
                                            }}
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                        >
                                            <AttachFileIcon style={{ color: postType === "photo" ? '#000' : '#fff', width: '20px', height: '20px' }} />
                                            <VisuallyHiddenInput type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                        </Button>

                                    </IconButton>
                                </Grid>

                                {/* <Grid item xs={isMobile ? 1.2 : 0.9}>
                                    <IconButton style={{ width: '28px', height: '28px', backgroundColor: '#3a3b3c' }}>
                                        <FormatQuoteIcon style={{ color: '#b0b3b8', width: '20px', height: '20px' }} />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={isMobile ? 1.2 : 0.9}>
                                    <IconButton style={{ width: '28px', height: '28px', backgroundColor: '#3a3b3c' }}>
                                        <CloudDownloadIcon style={{ color: '#b0b3b8', width: '20px', height: '20px' }} />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        )
                    }
                    <Grid item style={{ padding: '10px 20px', paddingTop: '20px' }}>
                        <Grid container item alignItems={"center"}>
                            <Grid item xs={isMobile ? 2.0 : 1.5}>
                                {
                                    userImage ? (
                                        <img
                                            src={userImage}
                                            alt={""}
                                            style={{
                                                color: "#fff",
                                                width: "42px",
                                                height: "42px",
                                                borderRadius: "100px",
                                            }}
                                        />
                                    ) : (
                                        <AccountCircleIcon sx={{ color: "#fff", width: "50px", height: "50px" }} />)
                                }
                            </Grid>

                            <Grid item xs={isMobile ? 10.0 : 10.5}>
                                <StyledTextField
                                    multiline
                                    minRows={1}
                                    maxRows={5}
                                    fullWidth
                                    placeholder="What's new?"
                                    sx={{
                                        border: 'none',
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onFocus={() => { setIsFocus(true) }}
                                // onBlur={() => { message === "" && setIsFocus(false) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        selectedFile && (
                            <Grid item style={{ padding: '10px 20px', }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '30px'
                                    }}
                                >
                                    {
                                        selectedFile['type']?.includes('video') ? (
                                            <>
                                                <video
                                                    width="220px"
                                                    height="220px"
                                                    style={{
                                                        borderRadius: "10px"
                                                    }}>
                                                    <source src={URL.createObjectURL(selectedFile)} alt={`Attachments: ${selectedFile?.name}`} />
                                                </video>
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={URL.createObjectURL(selectedFile)}
                                                    alt={`Attachments: ${selectedFile?.name}`}
                                                    width="220px"
                                                    height="220px"
                                                    style={{
                                                        borderRadius: "10px"
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                </Box>
                            </Grid>
                        )
                    }

                    {
                        isFocus && (
                            <Grid item mt={1} mb={2}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <StyledButtonGreen onClick={handleCreateNewPost}>
                                        Post
                                    </StyledButtonGreen>
                                </Box>
                            </Grid>
                        )
                    }

                </Grid>
            </CardContent>
        </StyledCard >
    )
}

export default AddPostCard;
import { getAllPosts, createPost, addNewComment, addLike, removeLike, deleteComment } from "../services/post.service";
import { NotificationToaster } from "../utils/notificationToaster";
import { toggleLoading } from './loading';
import { useSelector } from 'react-redux';

export const getPostsAction = (page, id) => async (dispatch, getState) => {
    // dispatch(toggleLoading(true));

    return getAllPosts(page, id)
        .then((res) => {
            const postsArray = getState().posts;

            if (postsArray === null) {
                dispatch({ type: "GET_POSTS_SUCCESS", payload: res.data.posts });
            } else {
                const updatedPostsArray = [...postsArray, ...res.data.posts]
                dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            }

            // dispatch(toggleLoading(false));
            return Promise.resolve(res.data.total_posts);
        })
        .catch((err) => {
            // dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error getting team updates", type: "error" });
            return Promise.reject(err);
        })
};

export const addPostAction = (message, fileUpload, postType) => async (dispatch, getState) => {
    dispatch(toggleLoading(true));

    const payload = new FormData();
    payload.append("message", message);
    payload.append("post_type", postType);

    if (fileUpload) {
        payload.append("file_attachment", fileUpload);
    }

    return createPost(payload)
        .then((res) => {
            const postsArray = getState().posts;
            const updatedPostsArray = [res.data.post, ...postsArray]

            dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            dispatch({ type: "REFRESH_DASH" });
            dispatch(toggleLoading(false));
            return Promise.resolve(res.data.posts);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error adding new post", type: "error" });
            return Promise.reject(err);
        })
};

export const addNewCommentAction = (message, postId) => async (dispatch, getState) => {
    dispatch(toggleLoading(true));

    const payload = {
        message: message,
        post_id: postId,
    }

    return addNewComment(payload)
        .then((res) => {
            const postsArray = getState().posts;

            const updatedPostsArray = postsArray.map((post) => {
                if (post.id === postId) {
                    post.comments = [...post.comments, res.data.comment];
                    post.comments_count = post.comments_count + 1;
                }
                return post;
            })

            dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error adding comment", type: "error" });
            return Promise.reject(err);
        })
};

export const addLikeAction = (postId) => async (dispatch, getState) => {
    // dispatch(toggleLoading(true));

    const payload = {
        post_id: postId,
    }

    return addLike(payload)
        .then((res) => {
            const postsArray = getState().posts;

            const updatedPostsArray = postsArray.map((post) => {
                if (post.id === postId) {
                    post.likes = [...post.likes, res.data.like];
                    post.likes_count = post.likes_count + 1;
                }
                return post;
            })

            dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            // dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            // dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error liking post", type: "error" });
            return Promise.reject(err);
        })
};

export const removeLikeAction = (postId, likeId) => async (dispatch, getState) => {
    // dispatch(toggleLoading(true));

    return removeLike(likeId)
        .then((res) => {
            const postsArray = getState().posts;

            const updatedPostsArray = postsArray.map((post) => {
                if (post.id === postId) {
                    post.likes = post.likes.filter((like) => like.id !== likeId);
                    post.likes_count = post.likes_count - 1;
                }
                return post;
            })

            dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            // dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            // dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error liking post", type: "error" });
            return Promise.reject(err);
        })
};

export const deleteCommentAction = (postId, commentId) => async (dispatch, getState) => {
    return deleteComment(commentId)
        .then((res) => {
            const postsArray = getState().posts;

            const updatedPostsArray = postsArray.map((post) => {
                if (post.id === postId) {
                    post.comments = post.comments.filter((comment) => comment.id !== commentId);
                    post.comments_count = post.comments_count - 1;
                }
                return post;
            })

            dispatch({ type: "UPDATE_POSTS", payload: updatedPostsArray });
            // dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            // dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error deleting comment", type: "error" });
            return Promise.reject(err);
        })
}
import { toggleLoading } from './loading';
import { markComplete, getChapterInfo, addReview } from '../services/chapter.service';
import { NotificationToaster } from "../utils/notificationToaster";

const getChapterInfoAction = (chapterID) => (dispatch) => {
    dispatch(toggleLoading(true));

    return getChapterInfo(chapterID)
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

const markCompleteAction = (chapterId) => (dispatch) => {
    dispatch(toggleLoading(true));

    return markComplete(chapterId)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Chapter Completed", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error submitting quiz", type: "error" });
            return Promise.reject(error);
        })
}

const addChapterReviewAction = (chapterId, message, rating) => (dispatch) => {
    dispatch(toggleLoading(true));

    const payload = {
        reviewable_id: chapterId,
        message: message,
        rating: rating,
        reviewable_type: "Chapter"
    }

    return addReview(payload)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Review Added", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error addding review", type: "error" });
            return Promise.reject(error);
        })
}

export {
    markCompleteAction, getChapterInfoAction, addChapterReviewAction
}
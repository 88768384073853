import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getChapterInfo = async (chapterID) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/chapters/${chapterID}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
}

const markComplete = async (chapterId) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/chapters/${chapterId}/mark_complete`, "",
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
    return response;
}

const addReview = async (payload) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(`${API_URL}/reviews`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response;
}

export {
    markComplete,
    getChapterInfo,
    addReview
}
export default function GuideReducer(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case "UPDATE_GUIDE":
            return payload;
        case "RESET_GUIDE":
            return null;
        default:
            return state;
    }
}
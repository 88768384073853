import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getSearchResources = async (query, payloadResource, payloadMedia) => {
    const token = localStorage.getItem("token");
    let QueryParams = `?query=${query}`;

    if (payloadResource?.length !== 0) {
        QueryParams += `&resource_type=${payloadResource[0]}`;
    }

    if (payloadMedia?.length !== 0) {
        QueryParams += `&resource_theme=${payloadMedia[0]}`;
    }

    const response = await axios.get(`${API_URL}/resources${QueryParams}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response;
};

const getResourceTypes = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/resource_types`)
    return response;
};

const getMediaTypes = async () => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${API_URL}/resource_themes`)
    return response;
};

export {
    getSearchResources,
    getResourceTypes,
    getMediaTypes
};
import { toggleLoading } from './loading';
import { NotificationToaster } from "../utils/notificationToaster";
import { getTeamActiveChallanges } from '../services/leaderboard.service';

const getTeamActiveChallangesAction = () => (dispatch) => {
    dispatch(toggleLoading(true));

    return getTeamActiveChallanges()
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response.data.guides);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

export { getTeamActiveChallangesAction };
export default function EnrolledGuidesReducer(state = null, action) {
    const { type, payload } = action;

    switch (type) {
        case "INIT_ENROLLED_GUIDES":
            return null;
        case "ENROLLED_GUIDES_SUCCESS":
            return payload;
        default:
            return state;
    }
}
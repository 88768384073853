import { toggleLoading } from './loading';
import { getSearchResources, getResourceTypes, getMediaTypes } from '../services/search.service';
import { NotificationToaster } from "../utils/notificationToaster";

const getTrueKeys = (obj) => {
    return Object.entries(obj)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
}

const getSearchResourcesAction = (query, resourceTypes, mediaTypes) => (dispatch) => {
    dispatch(toggleLoading(true));

    const payloadResource = getTrueKeys(resourceTypes);
    const payloadMedia = getTrueKeys(mediaTypes);

    return getSearchResources(query, payloadResource, payloadMedia)
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error loading resources", type: 'error' });
            return Promise.reject(error);
        })
}

const getResourceTypesAction = () => (dispatch) => {
    return getResourceTypes()
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            NotificationToaster({ message: "Error loading resource types", type: 'error' });
            return Promise.reject(error);
        })
}

const getMediaTypesAction = () => (dispatch) => {
    return getMediaTypes()
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            NotificationToaster({ message: "Error loading media types", type: 'error' });
            return Promise.reject(error);
        })
}

export {
    getSearchResourcesAction,
    getResourceTypesAction,
    getMediaTypesAction
}
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	Box,
	IconButton,
	useMediaQuery,
	InputAdornment,
	useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { changePasswordAction } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const StyledTextField = styled(TextField)(() => ({
	"& label": {
		color: "white",
		fontFamily: "Inter",
		fontWeight: 400,
	},
	"& label.Mui-focused": {
		color: "white",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "white",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "white",
		},
		"&:hover fieldset": {
			borderColor: "white",
		},
		"&.Mui-focused fieldset": {
			borderColor: "white",
		},
		backgroundColor: "#FFFFFF1A",
		color: "white",
	},
	".MuiFormHelperText-root.Mui-error": {
		color: "red",
	},
	marginBottom: "15px",
}));

const StyledButton = styled(Button)(() => ({
	backgroundColor: "#bdec5b",
	padding: "15px 30px 15px 30px",
	fontSize: "16px",
	fontWeight: 600,
	borderRadius: "100px",
	color: "#000",
	"&:hover": {
		backgroundColor: "#bdec5b",
		color: "#000",
	},
}));

const ChangePassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state.auth.user);

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm({});

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const [loading, setLoading] = useState(false);

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const handleClickShowPassword = () =>
		setShowCurrentPassword(!showCurrentPassword);
	const handleMouseDownPassword = () =>
		setShowCurrentPassword(!showCurrentPassword);

	const [showNewPassword, setShowNewPassword] = useState(false);
	const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
	const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);
	const handleMouseDownConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);

	const validateFormData = (formData) => {
		let isValid = true;

		if (formData.currentPassword === "") {
			setError("currentPassword", {
				message: "Current Password cannot be empty.",
			});
			isValid = false;
		}

		if (formData.newPassword === "") {
			setError("newPassword", { message: "New Password cannot be empty." });
			isValid = false;
		}

		if (formData.confirmPassword === "") {
			setError("confirmPassword", {
				message: "Confirm Password cannot be empty.",
			});
			isValid = false;
		}

		if (formData.newPassword !== formData.confirmPassword) {
			setError("newPassword", { message: "Passwords do not match." });
			setError("confirmPassword", { message: "Passwords do not match." });
			isValid = false;
		}

		return isValid;
	};

	const submitForm = (formData) => {
		clearErrors();
		setLoading(true);
		if (validateFormData(formData)) {
			const payload = {
				current_password: formData.currentPassword,
				new_password: formData.newPassword,
				password_confirmation: formData.confirmPassword,
				userID: userDetails?.id,
			};

			dispatch(changePasswordAction(payload))
				.then((response) => {})
				.catch((err) => {
					console.log(err);
				});
		}
		setLoading(false);
	};

	return (
		<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Grid
				container
				direction="column"
				spacing={3}
				width={isMobile ? "100%" : "700px"}
			>
				<Grid item>
					<Box
						onClick={() => navigate(-1)}
						sx={{
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							"&:hover": { cursor: "pointer" },
						}}
					>
						<IconButton>
							<ArrowBackIosIcon
								style={{ color: "#fff", width: "18px", height: "18px" }}
							/>
						</IconButton>

						<Typography
							fontFamily={"Inter"}
							fontWeight={700}
							fontSize={"16px"}
							color={"#fff"}
							lineHeight={"24px"}
						>
							Back
						</Typography>
					</Box>
				</Grid>
			</Grid>

			<Typography
				fontFamily={"Roboto"}
				fontWeight={600}
				fontSize={"20px"}
				color={"#fff"}
				mb={2}
			>
				Change Password
			</Typography>

			<form onSubmit={handleSubmit(submitForm)}>
				<Box sx={{ mt: 1, width: isMobile ? "90%" : "60%", margin: "auto" }}>
					<StyledTextField
						margin="normal"
						fullWidth
						name="currentPassword"
						label="Current Password"
						id="currentPassword"
						type={showCurrentPassword ? "text" : "password"}
						disabled={loading}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showCurrentPassword ? (
											<Visibility style={{ color: "#fff" }} />
										) : (
											<VisibilityOff style={{ color: "#fff" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						{...register("currentPassword")}
						error={!!errors.currentPassword}
						helperText={errors?.currentPassword?.message}
					/>

					<StyledTextField
						margin="normal"
						fullWidth
						name="newPassword"
						label="New Password"
						id="newPassword"
						type={showNewPassword ? "text" : "password"}
						disabled={loading}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowNewPassword}
										onMouseDown={handleMouseDownNewPassword}
									>
										{showNewPassword ? (
											<Visibility style={{ color: "#fff" }} />
										) : (
											<VisibilityOff style={{ color: "#fff" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						{...register("newPassword")}
						error={!!errors.newPassword}
						helperText={errors?.newPassword?.message}
					/>

					<StyledTextField
						margin="normal"
						fullWidth
						name="confirmPassword"
						label="Confirm Password"
						id="confirmPassword"
						type={showConfirmPassword ? "text" : "password"}
						disabled={loading}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmPassword}
										onMouseDown={handleMouseDownConfirmPassword}
									>
										{showConfirmPassword ? (
											<Visibility style={{ color: "#fff" }} />
										) : (
											<VisibilityOff style={{ color: "#fff" }} />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						{...register("confirmPassword")}
						error={!!errors.confirmPassword}
						helperText={errors?.confirmPassword?.message}
					/>

					<StyledButton
						fullWidth
						variant="contained"
						sx={{ mt: 4, mb: 2 }}
						type="submit"
						disabled={loading}
					>
						Update Password
					</StyledButton>
				</Box>
			</form>
		</Box>
	);
};

export default ChangePassword;

import Card from '@mui/material/Card';
import { ReactComponent as Spinner } from '../../assets/loader/spinner.svg';
import "./LoadingSpinner.css";

function LoadingSpinner() {
    return (

        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                backgroundColor: "#1d1d1d",
                maxWidth: '211.61px',
                maxHeight: '116px',
                boxShadow: "none"
            }}>
            <div>
                {/* <svg
                    width="110"
                    height="110"
                    viewBox="0 0 345 360"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="Group 1">
                        <path
                            id="top-left"
                            d="M49.4399 23.4286C41.4454 27.0714 36.8169 32.2143 32.6093 42.5C30.0847 48.7143 29.4536 60.5 29.4536 104V157.571H82.6803C133.593 157.571 136.328 157.357 145.164 152.643C163.046 143.429 163.046 143.643 163.678 78.0714L164.309 20.4286H110.03C69.6366 20.6429 54.0683 21.2857 49.4399 23.4286Z"
                            fill={"#8e277e"}
                        />
                        <path
                            id="top-right"
                            d="M232.473 24.4286C224.478 28.0714 219.85 33.2143 215.642 43.5C213.117 49.7143 212.486 61.5 212.486 105V158.571H265.713C316.626 158.571 319.361 158.357 328.197 153.643C346.079 144.429 346.079 144.643 346.71 79.0714L347.342 21.4286H293.063C252.669 21.6429 237.101 22.2857 232.473 24.4286Z"
                            fill={"#8e277e"}
                        />
                        <path
                            id="bottom-left"
                            d="M49.4399 210.857C41.4454 214.5 36.8169 219.643 32.6093 229.929C30.0847 236.143 29.4536 247.929 29.4536 291.429V345H82.6803C133.593 345 136.328 344.786 145.164 340.071C163.046 330.857 163.046 331.071 163.678 265.5L164.309 207.857H110.03C69.6366 208.071 54.0683 208.714 49.4399 210.857Z"
                            fill={"#8e277e"}
                        />
                        <path
                            id="bottom-right"
                            d="M235.628 209.357C227.844 212.357 220.06 219.857 216.484 228C212.907 235.714 212.486 242.786 212.486 290.786V345H265.713C316.626 345 319.361 344.786 328.197 340.071C346.079 330.857 346.079 331.071 346.71 265.5L347.342 207.857L293.063 208.071C263.189 208.071 237.311 208.714 235.628 209.357Z"
                            fill={"#8e277e"}
                        />
                    </g>
                </svg> */}
                <Spinner
                    width="110"
                    height="110"
                    // viewBox="0 0 345 360"
                    fill="none"
                // xmlns="http://www.w3.org/2000/svg"
                />
            </div>
        </Card>
    );
}

export default LoadingSpinner;

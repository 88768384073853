import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, useMediaQuery, useTheme, Button, styled, Grid, Typography, TextField, Modal, InputAdornment, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as LogoWhiteFull } from '../../assets/logo/logo-full-white.svg';
import LogoWhiteSmall from '../../assets/logo/logo-small-white.png';
import { Loading } from "../Loader/Loader";
import { useNavigate, useLocation } from 'react-router-dom';
import * as React from 'react';
import Popper from '@mui/material/Popper';
import { logout } from '../../services/auth.service';
import SearchIcon from '@mui/icons-material/Search';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '15px 30px 15px 30px',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: 'Attila Sans',
    borderRadius: '3px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    width: '85%',
    height: '36px',
    lineHeight: '14px',
    textTransform: 'none'
}));

const StyledTextField = styled(TextField)(({ isMobile }) => ({
    '& label': {
        color: 'white',
        fontFamily: 'Inter',
        fontWeight: 400
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#bdec5b',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#bdec5b',
        },
        '&:hover fieldset': {
            borderColor: '#bdec5b',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#bdec5b',
        },
        backgroundColor: "#FFFFFF1A",
        color: 'white',
        height: isMobile ? "45px" : "50px",
        width: isMobile ? "250px" : "500px",
        borderRadius: "40px"
    },
    '.MuiFormHelperText-root.Mui-error': {
        color: 'red'
    },
    marginRight: isMobile ? "5px" : "30px",
    marginTop: isMobile ? -1 : 0
}));

const Navbar = ({ version = '1' }) => {
    // hooks
    const loader = useSelector((state) => state.appLoader);
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const userImage = useSelector((state) => state?.auth.user?.avatar_url);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");

    const [navOpen, setNavOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (navOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            // Ensure the overflow is reset when the component unmounts
            document.body.style.overflow = 'auto';
        };
    }, [navOpen]);

    const handleToggleNav = () => {
        setNavOpen(!navOpen);
    };

    const handleCloseNav = () => {
        setNavOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickNav = (event) => {
        setAnchorElNav(anchorElNav ? null : event.currentTarget);
    };

    // const handleCloseNav = () => {
    //     setAnchorElNav(null);
    // };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const openNav = Boolean(anchorElNav);
    const idNav = openNav ? 'simple-popper-nav' : undefined;

    const handleLogout = () => {
        dispatch(logout())
            .then(() => {
                setAnchorEl(null);
                dispatch({ type: 'LOGOUT' });
                dispatch({ type: 'RESET_POSTS' });
                navigate("/");
            })
    }

    return (
        <>
            {
                version === '1' && (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <LogoWhiteFull />
                    </Box>
                )
            }

            {
                version === '2' && (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: "0 15px"
                            }}
                        >
                            <a href="/">
                                <img src={LogoWhiteSmall} alt={""} width={"50px"} height={"50px"} />
                            </a>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}
                            >
                                {/* <StyledTextField
                                    isMobile={isMobile}
                                    margin="normal"
                                    fullWidth
                                    name="search"
                                    placeholder='Search Resources...'
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    onKeyDown={(e) => { if (e.keyCode == 13) { navigate(`/search/${searchText}`) } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => { navigate(`/search/${searchText}`) }}>
                                                    <SearchIcon style={{ color: "#fff" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}

                                {
                                    !location.pathname.includes('search') && (
                                        // <Typography color={"#fff"} fontFamily={"Inter"} fontSize={"16px"} fontWeight={600} onClick={() => { navigate(`/search/${searchText}`) }} style={{ cursor: 'pointer' }}>
                                        //     Inspire
                                        // </Typography>
                                        <SearchIcon style={{ color: '#fff', height: '40px', width: '40px', cursor: 'pointer' }} onClick={() => { navigate(`/search/${searchText}`) }} />
                                    )
                                }

                                {
                                    userImage ? (
                                        <img
                                            src={userImage}
                                            alt={""}
                                            style={{
                                                color: "#fff",
                                                width: "42px",
                                                height: "42px",
                                                borderRadius: "100px",
                                                cursor: "pointer"
                                            }}
                                            aria-describedby={id}
                                            onClick={handleClick}
                                        />
                                    ) : (
                                        <AccountCircleIcon sx={{ color: "#fff", width: "42px", height: "42px", cursor: "pointer" }} aria-describedby={id} onClick={handleClick} />
                                    )
                                }
                            </Box>

                            <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-start'>
                                <Box
                                    sx={{
                                        width: '170px',
                                        backgroundColor: 'rgb(58, 59, 60)',
                                        paddingTop: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingBottom: 1,
                                        marginRight: '10px'
                                    }}
                                >
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12} style={{ borderBottom: '1px solid #000', paddingBottom: 8, width: '205px' }}>
                                            <Typography style={{ color: '#fff', cursor: 'pointer' }} onClick={() => { setAnchorEl(null); navigate(`/editProfile`) }}>
                                                Edit Profile
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} style={{ borderBottom: '1px solid #000', paddingBottom: 8, width: '205px' }}>
                                            <Typography style={{ color: '#fff', cursor: 'pointer' }} onClick={() => { setAnchorEl(null); navigate(`/changePassword`) }}>
                                                Change Password
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} onClick={handleLogout}>
                                            <Typography style={{ color: '#fff', marginTop: -6, cursor: 'pointer' }}>
                                                Logout
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popper>
                        </Box>
                    </Box>
                )
            }

            {
                version === '3' && (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '20px',
                            justifyContent: 'center',
                            zIndex: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: "0 15px",
                                width: isMobile ? "100%" : "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: isMobile ? '5px' : '15px'
                                }}
                            >
                                <a href="/">
                                    <img src={LogoWhiteSmall} alt={""} width={"42px"} height={"42px"} />
                                </a>
                                {/* {
                                    isMobile && (
                                        <DensityMediumIcon style={{ color: "#fff" }} />
                                    )
                                } */}

                                {
                                    !isMobile && (
                                        <>
                                            <Typography style={{ cursor: 'pointer' }} fontSize={'18px'} fontWeight={500} fontFamily={'Attila Sans'} color={location.pathname.includes('mission') ? "#bdec5b" : "#fff"} onClick={() => navigate('/mission')}>
                                                Mission
                                            </Typography>

                                            <Typography style={{ cursor: 'pointer' }} fontSize={'18px'} fontWeight={500} fontFamily={'Attila Sans'} color={location.pathname.includes('challangespage') ? "#bdec5b" : "#fff"} onClick={() => navigate('/challangespage')}>
                                                Challenges
                                            </Typography>

                                            <Typography style={{ cursor: 'pointer' }} fontSize={'18px'} fontWeight={500} fontFamily={'Attila Sans'} color={location.pathname.includes('inspirepage') ? "#bdec5b" : "#fff"} onClick={() => navigate('/inspirepage')}>
                                                Inspire
                                            </Typography>
                                        </>
                                    )
                                }
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {/* {
                                    userImage ? (
                                        <img
                                            src={userImage}
                                            alt={""}
                                            style={{
                                                color: "#fff",
                                                width: "42px",
                                                height: "42px",
                                                borderRadius: "100px",
                                            }}
                                        />
                                    ) : (
                                        <AccountCircleIcon sx={{ color: "#fff", width: "42px", height: "42px", marginRight: '14px' }} />)
                                } */}

                                {
                                    isMobile && (
                                        <>
                                            <IconButton onClick={handleToggleNav} style={{ color: '#fff' }}>
                                                {navOpen ? <CloseIcon /> : <DensityMediumIcon />}
                                            </IconButton>

                                            <Modal
                                                open={navOpen}
                                                onClose={handleCloseNav}
                                                aria-labelledby="modal-title"
                                                aria-describedby="modal-description"
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start',
                                                    bgcolor: 'rgb(0, 0, 0)',
                                                    marginTop: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    zIndex: 1300,
                                                }}
                                            >
                                                <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                                                    <IconButton
                                                        onClick={handleCloseNav}
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 8,
                                                            right: 8,
                                                            color: '#fff',
                                                            zIndex: 1400,
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Grid container direction="column" spacing={2} sx={{ padding: 2 }}>
                                                        <Grid item xs={12} sx={{ borderBottom: '0.5px solid #fff', paddingBottom: 2, width: '100%' }}>
                                                            <Typography
                                                                sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 500, fontFamily: 'Attila Sans', color: location.pathname.includes('mission') ? '#bdec5b' : '#fff' }}
                                                                onClick={() => {
                                                                    navigate('/mission');
                                                                    handleCloseNav();
                                                                }}
                                                            >
                                                                Mission
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ borderBottom: '0.5px solid #fff', paddingBottom: 2, width: '100%' }}>
                                                            <Typography
                                                                sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 500, fontFamily: 'Attila Sans', color: location.pathname.includes('challangespage') ? '#bdec5b' : '#fff' }}
                                                                onClick={() => {
                                                                    navigate('/challangespage');
                                                                    handleCloseNav();
                                                                }}
                                                            >
                                                                Challenges
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ borderBottom: '0.5px solid #fff', paddingBottom: 2, width: '100%' }}>
                                                            <Typography
                                                                sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 500, fontFamily: 'Attila Sans', color: location.pathname.includes('inspirepage') ? '#bdec5b' : '#fff' }}
                                                                onClick={() => {
                                                                    navigate('/inspirepage');
                                                                    handleCloseNav();
                                                                }}
                                                            >
                                                                Inspire
                                                            </Typography>
                                                        </Grid>
                                                        {isLoggedIn ? (
                                                            <Grid item xs={12} sx={{ borderBottom: '0.5px solid #fff', paddingBottom: 2, width: '100%' }}>
                                                                <Typography
                                                                    sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 500, fontFamily: 'Attila Sans', color: location.pathname.includes('dashboard') ? '#bdec5b' : '#fff' }}
                                                                    onClick={() => {
                                                                        navigate('/dashboard');
                                                                        handleCloseNav();
                                                                    }}
                                                                >
                                                                    Go to Dashboard
                                                                </Typography>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12} sx={{ borderBottom: '0.5px solid #fff', paddingBottom: 2, width: '100%' }}>
                                                                <Typography
                                                                    sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 500, fontFamily: 'Attila Sans', color: location.pathname.includes('login') ? '#bdec5b' : '#fff' }}
                                                                    onClick={() => {
                                                                        navigate('/login');
                                                                        handleCloseNav();
                                                                    }}
                                                                >
                                                                    Login
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Modal>
                                        </>
                                    )
                                }

                                {
                                    !isMobile && (
                                        <StyledButton style={{ width: '210px' }} onClick={() => { isLoggedIn ? navigate("/dashboard") : navigate("/login") }}>
                                            {isLoggedIn ? "Go to dashboard" : "Login"}
                                        </StyledButton>
                                    )
                                }
                            </Box>
                        </Box >
                    </Box >
                )
            }

            {
                version === '4' && (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: "0 15px"
                            }}
                        >
                            <img src={LogoWhiteSmall} alt={""} width={"42px"} height={"42px"} />

                            {
                                userImage ? (
                                    <img
                                        src={userImage}
                                        alt={""}
                                        style={{
                                            color: "#fff",
                                            width: "42px",
                                            height: "42px",
                                            borderRadius: "100px",
                                            cursor: "pointer"
                                        }}
                                        aria-describedby={id}
                                        onClick={handleClick}
                                    />
                                ) : (
                                    <AccountCircleIcon sx={{ color: "#fff", width: "42px", height: "42px", cursor: "pointer" }} aria-describedby={id} onClick={handleClick} />
                                )
                            }

                            <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-start'>
                                <Box
                                    sx={{
                                        width: '170px',
                                        backgroundColor: 'rgb(58, 59, 60)',
                                        paddingTop: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingBottom: 1,
                                        marginRight: '10px'
                                    }}
                                >
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12} onClick={handleLogout} style={{ paddingTop: '20px' }}>
                                            <Typography style={{ color: '#fff', marginTop: -6, cursor: 'pointer' }}>
                                                Logout
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Popper>
                        </Box>
                    </Box>
                )
            }

            <ToastContainer position={"top-right"} autoClose={3000} />
            <Loading fullScreen open={loader.isOpen} message={loader.message} />
        </>
    )
}
export default Navbar;
import { getContributors } from "../services/landing.service";
import { toggleLoading } from './loading';
import { NotificationToaster } from "../utils/notificationToaster";

export const getContributorsAction = () => async (dispatch, getState) => {
    dispatch(toggleLoading(true));

    return getContributors()
        .then((res) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(res.data.contributors);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error getting contributors", type: "error" });
            return Promise.reject(err);
        })
};
import { Box, Button, Grid, useMediaQuery, useTheme, Card, Chip, CardContent, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StarIcon from '../assets/icons/dashboard/star.svg';
import { getAllGuidesAction, enrollGuidesAction } from '../actions/challange';
import { getTeamAction} from '../actions/team';
import Radio from '@mui/material/Radio';
import InfoIcon from '@mui/icons-material/Info';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlurryPlaceHolder from '../assets/placeholderBlurry.png';

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#bdec5b',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Attila Sans',
    borderRadius: '100px',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bdec5b',
        color: '#000',
    },
    height: '50px',
    textTransform: 'none'
}));

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : '100%',
    '&:hover': { cursor: 'pointer' }
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));

const EnrollGuides = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const enrolledGuides = useSelector((state) => state.enrolledGuides);

    let enrolledActionGuidesIdsLst = [];
    let enrolledDiscoveryGuideId = null;

    if (enrolledGuides) {
        for (let i = 0; i < enrolledGuides.length; i++) {
            if (enrolledGuides[i].guide_type === "Discovery") {
                enrolledDiscoveryGuideId = enrolledGuides[i].id
            } else {
                enrolledActionGuidesIdsLst.push(enrolledGuides[i].id);
            }
        }
    }

    const [guides, setGuides] = useState([]);
    const [team, setTeam] = useState([]);
    const [discoveryGuide, setDiscoveryGuide] = useState(enrolledDiscoveryGuideId);
    const [actionGuides, setActionGuides] = useState(enrolledActionGuidesIdsLst);

    const handleActionGuideSelect = (guideId) => {
        if (actionGuides.length === 2 && actionGuides.includes(guideId)) {
            setActionGuides(actionGuides.filter((obj) => obj !== guideId));
        } else if (actionGuides.includes(guideId)) {
            setActionGuides(actionGuides.filter((obj) => obj !== guideId));
        } else if (actionGuides.length !== 2) {
            setActionGuides([...actionGuides, guideId]);
        }
    }

    const handleViewGuide = (e, guide) => {
        e.preventDefault();
        dispatch({ type: "UPDATE_GUIDE", payload: guide });
        navigate("/guideInfo");
        e.stopPropagation();
    }

    const handleEnrollGuides = () => {
        let guideLst = [];
        guideLst.push(discoveryGuide);
        actionGuides.forEach((guide) => {
            guideLst.push(guide);
        });

        dispatch(enrollGuidesAction(guideLst))
            .then((res) => {
                navigate("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        dispatch(getAllGuidesAction())
            .then((res) => {
                setGuides(res.data.guide_types.slice(0, 2));
                console.log(res.data.guide_types.slice(0, 2))
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        dispatch(getTeamAction())
            .then((res) => {
                setTeam(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <Box
            sx={{
                padding: isMobile ? '24px' : '28px',
                paddingTop: 4
            }}
        >
            <Grid container direction="column" spacing={0} width={isMobile ? '100%' : '700px'}>
                <Grid item>
                    <Box
                        onClick={() => navigate(-1)}
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': { cursor: 'pointer' }
                        }}
                    >
                        <IconButton>
                            <ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
                        </IconButton>

                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                            Back
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Typography fontFamily={"Inter"} fontWeight={900} fontSize={'24px'} color={"#fff"} mt={2} mb={4} lineHeight={'29px'}>
                Choose your challenges!
            </Typography>

            <Box
                sx={{
                    marginBottom: '30px'
                }}>
                <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'20px'} color={"#fff"} mb={1} lineHeight={'29px'}>
                    {guides[0]?.name} Guide
                </Typography>

                <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'18px'} color={"#fff"} mb={2} lineHeight={'29px'}>
                    Choose 1 
                </Typography>

                <Grid container spacing={2}>
                    {
                        guides[0]?.guides?.map((guide) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 3}>
                                    <StyledCard style={{ border: discoveryGuide === guide.id ? "1px solid #bdec5b" : "none" }} onClick={() => { discoveryGuide === guide.id ? setDiscoveryGuide(null) : setDiscoveryGuide(guide.id) }}>
                                        <CardContent>
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={3.5}>
                                                    <LazyLoadImage src={guide?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" width="85px" height="85px" style={{ borderRadius: '12px' }} />
                                                </Grid>

                                                <Grid item xs={7.5}>
                                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                        {guide?.title}
                                                    </Typography>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '6px',
                                                            marginTop: '6px'
                                                        }}
                                                    >
                                                        <StyledChip label={guide?.guide_type} />

                                                        {
                                                            guide?.themes?.length !== 0 && (
                                                                guide.themes.map((theme) => {
                                                                    return (
                                                                        <StyledChip label={theme} />
                                                                    )
                                                                })
                                                            )
                                                        }

                                                        <InfoIcon onClick={(e) => handleViewGuide(e, guide)} />
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: 'inline-flex',
                                                            marginTop: '10px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                                            <img src={StarIcon} alt="" style={{ height: '20px' }} />
                                                        </IconButton>

                                                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                                            {guide.total_points}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <Radio
                                                        style={{
                                                            color: '#bdec5b'
                                                        }}
                                                        checked={discoveryGuide === guide.id}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
            {team?.team?.days_to_unlock >= 14 &&(        
            <Box
                sx={{
                    marginBottom: '30px'
                }}>
                <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'20px'} color={"#fff"} mb={1} lineHeight={'29px'}>
                    {guides[1]?.name} Guide
                </Typography>

                <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'18px'} color={"#fff"} mb={2} lineHeight={'29px'}>
                    Choose 2 (max)
                </Typography>

                <Grid container spacing={2}>
                    {
                        guides[1]?.guides?.map((guide) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 3}>
                                    <StyledCard style={{ border: actionGuides.includes(guide.id) ? "1px solid #bdec5b" : "none" }} onClick={() => { handleActionGuideSelect(guide.id) }}>
                                        <CardContent>
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={3.5}>
                                                    <LazyLoadImage src={guide?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" width="85px" height="85px" style={{ borderRadius: '12px' }} />
                                                </Grid>

                                                <Grid item xs={7.5}>
                                                    <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                        {guide?.title}
                                                    </Typography>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '6px',
                                                            marginTop: '6px'
                                                        }}
                                                    >
                                                        <StyledChip label={guide?.guide_type} />

                                                        {
                                                            guide?.themes?.length !== 0 && (
                                                                guide.themes.map((theme) => {
                                                                    return (
                                                                        <StyledChip label={theme} />
                                                                    )
                                                                })
                                                            )
                                                        }

                                                        <InfoIcon onClick={(e) => handleViewGuide(e, guide)} />
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: 'inline-flex',
                                                            marginTop: '10px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                                            <img src={StarIcon} alt="" style={{ height: '20px' }} />
                                                        </IconButton>

                                                        <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                                            {guide.total_points}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <Radio
                                                        style={{
                                                            color: '#bdec5b'
                                                        }}
                                                        checked={actionGuides.includes(guide.id)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>)}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <StyledButton onClick={() => { handleEnrollGuides() }} style={{ width: isMobile ? "100%" : "25%", marginTop: isMobile ? 0 : "20px" }}>
                    Confirm Selection
                </StyledButton>
            </Box>

        </Box >
    )
}

export default EnrollGuides;
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

const getContributors = async () => {
    const response = await axios.get(`${API_URL}/contributors`)
    return response;
};

export {
    getContributors
}
import { Box, Card, CardContent, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? '100px' : '115px'
}));

const DashboardCard = ({ label, icon, value, isPost = false,onClick, scrollToSection = false }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledCard isMobile={isMobile} onClick={isPost ? () => { scrollToSection("feed-section") } : onClick}>
            <CardContent>
                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                    {label}
                </Typography>

                {
                    !isPost && (
                        <Box
                            sx={{
                                display: 'inline-flex',
                                marginTop: '10px',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                <img src={icon} alt="" style={{ height: '30px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'}>
                                {value}
                            </Typography>
                        </Box>
                    )
                }

                {
                    isPost && (
                        <Box
                            sx={{
                                display: 'inline-flex',
                                marginTop: '10px',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                <img src={icon} alt="" style={{ height: '30px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'}>
                                {value}
                            </Typography>
                        </Box>
                    )
                }
            </CardContent>
        </StyledCard>
    )
}

export default DashboardCard;
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    isLoggedIn: !!user,
    errorMessage: null,
    user: user ?? null
}
export default function AuthReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case "LOGIN_ATTEMPT_INIT":
            return {
                ...state,
            }
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                user: payload,
            };
        case "LOGIN_FAIL":
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                errorMessage: payload,
            };
        case "LOGOUT":
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                errorMessage: null,
            };
        case "UPDATE_USER_DETAILS":
            return {
                ...state,
                isLoggedIn: true,
                user: payload,
            };
        default:
            return state;
    }
}